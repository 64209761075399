export const indicatorsConstants = {
  ACCURED: "ACCURED",
  CENTURY: "CENTURY", // ;-)
  MONTH: "MONTH",
  NEW: "NEW",
  WEEK: "WEEK",
  YEAR: "YEAR",
};

export const doughnutColors = {
  BLUE: "rgba(80, 173, 188, 0.8)",
  BLUE_DARK: "rgba(56, 71, 100, 0.8)",
  GREEN: "rgba(63, 141, 79, 0.8)",
  GREY: "rgba(56, 71, 100, 0.5)",
  ORANGE: "rgba(236, 103, 7, 0.8)",
  RED: "rgba(226, 82, 65, 0.8)",
};
