import API from "helpers/api";
import { authHeader } from "../helpers/authHeader";

async function thirdAccessAccountList() {
  const result = {};
  const path = "services/backOffice/account/thirdAccess";

  const response = await API.get(path, { headers: authHeader() });
  const dt = response.data;
  if (dt.error === false) {
    result.thirdAccount = dt.data.accountList;
    result.selectedAccount = dt.data.selectedAccount;
  }
  return result;
}

async function thirdAccessAccountSwitch(selectedIdAccount) {
  let result = {};
  const path = "services/backOffice/account/thirdAccess/switch";

  const params = { parmas: { idAccount: selectedIdAccount }, headers: authHeader() };

  const response = await API.get(path, params);
  const dt = response.data;
  if (dt.error === false) {
    result = dt.data;
  }
  return result;
}

export const accountService = {
  thirdAccessAccountList,
  thirdAccessAccountSwitch,
};
