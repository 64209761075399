import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TimerIcon from "@mui/icons-material/Timer";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import useSinceTime from "hooks/useSinceTime";
import { fancyTimeFormat } from "utils";
import AlertAutomaticCloseDialog from "fdbs/layouts/dashboards/alert/dialogs/AlertAutomaticCloseDialog/AlertAutomaticCloseDialog";
import AlertConfirmCloseDialog from "fdbs/layouts/dashboards/alert/dialogs/AlertConfirmCloseDialog/AlertConfirmCloseDialog";

export default function AlertBannerInProgress({ onClose, onRefresh }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const [disableForm, setDisableForm] = useState(false);
  const [openAutomaticCloseDialog, setOpenAutomaticCloseDialog] = useState(false);
  const [openConfirmCloseDialog, setOpenConfirmCloseDialog] = useState(false);
  const sinceTime = useSinceTime(currentAlert.creationDate, currentAlert.status === 2);

  useEffect(() => {
    /** Mise à jour automatique toutes les 30 secondes */
    if (sinceTime > 0 && sinceTime % 30 === 0) {
      onRefresh();
    }

    /** Demande de fermeture d'une alerte au bout de 20mn */
    if (sinceTime === 1200) {
      setOpenAutomaticCloseDialog(true);
    }
  }, [sinceTime]);

  function dialogAnswerHandler(answer) {
    setOpenAutomaticCloseDialog(false);
    setOpenConfirmCloseDialog(false);
    if (answer) {
      handleClose();
    }
  }

  /**
   * Actions à la fermeture d'une alerte
   */
  async function handleClose() {
    setDisableForm(true);
    const closed = await onClose();
    setDisableForm(false);
    if (closed) navigate(`/dashboards/default`);
  }

  if (currentAlert.close !== 1 && currentAlert.status === 2) {
    return (
      <SoftBox
        px={5}
        py={3}
        sx={(theme) => ({
          backgroundColor: theme.palette.white.main,
          boxShadow: "0px -3px 4px rgba(0, 0, 0, 0.25)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        <SoftTypography component="p" variant="button" fontWeight="medium" color="text">
          <TimerIcon sx={{ verticalAlign: "middle" }} /> {fancyTimeFormat(sinceTime)}
        </SoftTypography>
        <SoftButton
          id="close-button"
          variant="contained"
          type="submit"
          color="primary"
          disabled={disableForm || currentAlert.close === 1}
          onClick={() => {
            setOpenConfirmCloseDialog(true);
          }}
        >
          {t("alert.button.closeAlert")}
        </SoftButton>
        <AlertAutomaticCloseDialog
          open={openAutomaticCloseDialog}
          onClose={() => dialogAnswerHandler(true)}
        />
        <AlertConfirmCloseDialog
          open={openConfirmCloseDialog}
          onClose={(answer) => dialogAnswerHandler(answer)}
        />
      </SoftBox>
    );
  }
  return null;
}
