import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "fdbs/layouts/LayoutContainers/DashboardLayout";
import Last24Section from "./Last24Section/Last24Section";
import ComparisonsSection from "./ComparisonsSection/ComparisonsSection";
import MonitoringCitizensResponders from "./MonitoringCitizensResponders/MonitoringCitizensResponders";
import MonitoringAlerts from "./MonitoringAlerts/MonitoringAlerts";

export default function DashboardPage() {
  const { t } = useTranslation();
  const currentUser = useSelector((store) => store.userState.user);
  const [filter, setFilter] = useState({ area: "" });

  const areasList = [
    {
      name: currentUser?.accountName,
      value: "",
    },
    { name: "France", value: "FR" },
  ];

  return (
    <DashboardLayout pageTitle={t("page.dashboard.title")}>
      <SoftBox
        height="80px"
        bgColor="white"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <SoftBox
          px={0.5}
          py={0.75}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            borderRadius: "9px",
            "& .MuiButtonBase-root": { margin: "2px", minWidth: "120px" },
          }}
        >
          {areasList.map((area) => (
            <SoftButton
              key={area.value}
              size="small"
              data-value={area.value}
              onClick={(event) => setFilter({ area: event.target.dataset.value })}
              variant={filter.area === area.value ? "contained" : "text"}
              color={filter.area === area.value ? "primary" : "dark"}
              sx={{ fontSize: "1rem" }}
            >
              {area.name}
            </SoftButton>
          ))}
        </SoftBox>
      </SoftBox>
      <Container maxWidth="lg">
        <Last24Section filter={filter} />
        <MonitoringCitizensResponders filter={filter} />
        <MonitoringAlerts filter={filter} />
      </Container>
      <SoftBox
        bgColor="white"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Container maxWidth="lg">
          <ComparisonsSection />
        </Container>
      </SoftBox>
    </DashboardLayout>
  );
}
