import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import aedBackground from "fdbs/assets/images/aedBackground.png";
import useGetLast24 from "hooks/indicators/useGetLast24";
import HeatmapDialog from "../HeatmapDialog/HeatmapDialog";
import useGetAedsHeatmap from "hooks/indicators/useGetAedsHeatmap";

function NumberCard({ value = 0, text = "" }) {
  return (
    <SoftBox
      p={1.5}
      sx={{
        backgroundColor: (theme) => theme.palette.white.main,
        borderRadius: "16px",
        boxShadow: "0px 2px 7px rgba(56, 71, 100, 0.3)",
        minHeight: "105px",
        height: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SoftTypography variant="h2" sx={{ paddingBottom: "0.5rem" }}>
        {value}
      </SoftTypography>
      {text && <SoftTypography variant="body1">{text}</SoftTypography>}
    </SoftBox>
  );
}

export default function Last24Section({ filter }) {
  const { t } = useTranslation();
  const [openHeatmap, setOpenHeatmap] = useState(false);
  const stats = useGetLast24(filter);
  const heatmapData = useGetAedsHeatmap(filter);

  return (
    <SoftBox component="section" py={5}>
      <SoftTypography variant="h4">{t("dashboard.last24h.title")}</SoftTypography>
      {!stats ? (
        <SoftBox sx={{ textAlign: "center" }} mt={5}>
          <CircularProgress />
        </SoftBox>
      ) : (
        <Grid container spacing={3} alignItems="stretch" pt={4}>
          <Grid item xs={6} container spacing={3} alignItems="stretch">
            <Grid item xs={6}>
              <NumberCard
                value={stats.nbRespondersInArea.toLocaleString()}
                text={t("dashboard.last24h.CitizensRespondersPresent")}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberCard value={stats.nbAlertsLast24} text={t("dashboard.last24h.alerts")} />
            </Grid>
            <Grid item xs={6}>
              <NumberCard
                value={stats.nbRespondersContactedLast24}
                text={t("dashboard.last24h.CitizensRespondersContacted")}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberCard
                value={stats.nbRespondersEngagedLast24}
                text={t("dashboard.last24h.CitizensRespondersInvolved")}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <SoftBox
              p={1.5}
              sx={{
                backgroundColor: (theme) => theme.palette.white.main,
                backgroundImage: `url(${aedBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                borderRadius: "16px",
                boxShadow: "0px 2px 7px rgba(56, 71, 100, 0.3)",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SoftBox sx={{ width: "50%" }}></SoftBox>
              <SoftBox>
                <SoftTypography variant="h2" sx={{ fontSize: "2rem" }}>
                  {t("dashboard.last24h.totalAeds")}
                </SoftTypography>
                <SoftBox sx={{ display: "flex", alignItems: "flex-end" }}>
                  <SoftTypography variant="h2" sx={{ fontWeight: "400" }}>
                    {stats.nbAedsInArea.toLocaleString()}
                  </SoftTypography>
                  <SoftTypography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      padding: "0 0 0.25rem 0.5rem",
                    }}
                  >
                    {t("dashboard.last24h.newAeds", { newAeds: stats.nbAedsInAreaSince24 })}
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  color="primary"
                  sx={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    paddingTop: "1.375rem",
                    " &:hover": { textDecoration: "inherit", cursor: "pointer" },
                  }}
                  onClick={() => setOpenHeatmap(true)}
                >
                  {t("dashboard.last24h.open.aedsHeatmap")}
                </SoftTypography>
                <HeatmapDialog
                  data={heatmapData}
                  open={openHeatmap}
                  onClose={() => setOpenHeatmap(false)}
                  title={t("dashboard.dialog.aedsHeatmap.title")}
                />
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      )}
    </SoftBox>
  );
}
