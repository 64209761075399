import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SoftBox from "components/SoftBox";

import AlertBannerInProgress from "./AlertBannerInProgress/AlertBannerInProgress";
import AlertClosedDialog from "./dialogs/AlertClosedDialog/AlertClosedDialog";
import AlertConfirmUpdateDialog from "./dialogs/AlertConfirmUpdateDialog/AlertConfirmUpdateDialog";
import AlertFormLayout from "./AlertFormLayout/AlertFormLayout";
import CitizenResponders from "./CitizenResponders/CitizenResponders";
import VideoCard from "fdbs/components/Cards/VideoCard";

import DashboardLayout from "fdbs/layouts/LayoutContainers/DashboardLayout";
import GenericErrorDialog from "fdbs/components/GenericErrorDialog/GenericErrorDialog";
import Map from "./Map/Map";
import TestBanner from "./TestBanner/TestBanner";
import useAlertWebSocket from "hooks/useAlertWebSocket";
import useErrorFormater from "hooks/useErrorFormater";
import useGetAlertFromURL from "hooks/useGetAlertFromURL";
import useGetAlertOptions from "hooks/useGetAlertOptions";
import Tutorial from "./Tutorial/Tutorial";

export default function AlertPage({ actions, isDemoMode = false }) {
  const { alert, closeAlertAction, onCall, settingUpAlert, updtAlert } = actions;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isActive, dispatchWebSocket] = useAlertWebSocket();
  const [addErrorMsg] = useErrorFormater();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const currentSelectCountry = useSelector((store) => store.alertState.countrySelected);
  const [potentialUpdates, setPotentialUpdates] = useState();
  const [videoPopin, setVideoPopin] = useState(null);

  useGetAlertOptions();
  useGetAlertFromURL();

  // useEffect(() => {
  //   console.log("ICIC");
  //   const responder = {
  //     citizenResponderId: 54608,
  //     number: 1,
  //     name: "Looc Queriel",
  //     age: 48,
  //     phone: "+33 677044213",
  //     app: "Staying Alive",
  //     training: "TRAINED_WITHOUT_DIPLOMA",
  //     certificated: false,
  //     skill: "Professionnel de Santé",
  //     skillSpeciality: "Kinésithérapeute",
  //     contact: false,
  //     videoSessionId: "1_MX40NjI3MTUzMn5-MTcwMDgyMDQ1NTc1NH5qM0g1QnU1VlpSK2pXOWVUeWcrejVHMEN-UH5-",
  //     videoToken:
  //       "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZGIyZjM1ZjkyZWNiODUyMDQ3N2NiMzQ0ZmQzMzA2MzU0ZmZhMjU1MTpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UY3dNRGd5TURRMU5UYzFOSDVxTTBnMVFuVTFWbHBTSzJwWE9XVlVlV2NyZWpWSE1FTi1VSDUtJmNyZWF0ZV90aW1lPTE3MDA4MjA0NTYmbm9uY2U9MC4yNTA3NDkwMjM2ODI1Nzk1NiZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNzAwOTA2ODU2JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9",
  //     alertInfo: {
  //       lon: "2.5306489",
  //       lat: "48.8457338",
  //       AED: 0,
  //       status: "ON_SITE_VICTIM",
  //       distance: 153,
  //       duration: 39,
  //     },
  //   };
  //   setVideoPopin(responder);
  // }, []);

  async function onVideoLaunched(responder) {
    setVideoPopin(responder);
  }

  async function onVideoCloseClicked() {
    setVideoPopin(null);
  }

  /** Websocket */
  useEffect(() => {
    if (isDemoMode === false && currentAlert.close === 0 && currentAlert.status === 2) {
      dispatchWebSocket({ type: "CONNEXION" });
    } else {
      dispatchWebSocket({ type: "CLOSE" });
    }
  }, [currentAlert.close, currentAlert.status, isDemoMode]);

  /**
   * Gestionnaire de mises à jour de l'alerte
   * @param {*} updates - Mises à jour à apporter à l'alerte chargée
   */
  async function alertChangeHandler(updates) {
    const alertId = currentAlert.alertId ? currentAlert.alertId : 0;

    async function prepareAlert(updates) {
      try {
        await dispatch(
          settingUpAlert(
            updates.lon ? updates.lon : currentAlert.lon,
            updates.lat ? updates.lat : currentAlert.lat,
            updates.address ? updates.address : currentAlert.address,
            updates.detail ? updates.detail : currentAlert.addressInfo,
            currentSelectCountry,
            updates.alertType ? updates.alertType : currentAlert.alertType,
            alertId
          )
        );
      } catch (error) {
        addErrorMsg("settingUpAlert", error);
      }
    }

    if (!currentAlert.close) {
      if (alertId > 0 && currentAlert.status === 1) {
        await prepareAlert(updates);
      } else if (alertId > 0 && currentAlert.status === 2) {
        setPotentialUpdates(updates);
      } else if (updates.lon && updates.lat && updates.address) {
        // Dans le cas d'une création, la route impose qu'au minimum il y ait la longitude / latitude / adresse
        await prepareAlert(updates);
      }
    }
  }

  /**
   * Arrête l'alerte en cours présente dans le store Redux
   * @returns {boolean} Retourne si la fermeture s'est bien passée.
   */
  async function closeAlert() {
    if (!currentAlert.close && currentAlert.status === 2) {
      try {
        await dispatch(closeAlertAction(currentAlert.alertId));
        dispatchWebSocket({ type: "SEND_MESSAGE_CLOSE_ALERT", alertId: currentAlert.alertId });
        return true;
      } catch (error) {
        addErrorMsg("closeAlert", error);
        return false;
      }
    }
    return false;
  }

  /**
   * Gestion de la réponse qui confirme ou non l'envoi des modifications d'une alerte en cours
   * @param {boolean} answer Réponse de l'utilisateur. True s'il confirme.
   */
  async function confirmUpdatesAnswerHandler(answer) {
    const updates = potentialUpdates;
    if (answer) {
      try {
        await dispatch(
          updtAlert(
            currentAlert.alertId,
            updates.lon ? updates.lon : currentAlert.lon,
            updates.lat ? updates.lat : currentAlert.lat,
            updates.address ? updates.address : currentAlert.address,
            updates.detail ? updates.detail : currentAlert.addressInfo
          )
        );
        dispatchWebSocket({ type: "SEND_MESSAGE_REFRESH_ALERT", alertId: currentAlert.alertId });
      } catch (error) {
        addErrorMsg("updateAlert", error);
      }
    }
    setPotentialUpdates();
  }

  /**
   * Déclenche l'alerte présente dans le store Redux
   * @returns {boolean} Retourne si le déclenchement s'est bien passé.
   */
  async function launchAlert() {
    if (!currentAlert.close && currentAlert.status === 1) {
      try {
        await dispatch(
          alert(
            currentAlert.alertId,
            currentAlert.lon,
            currentAlert.lat,
            currentAlert.address,
            currentAlert.addressInfo,
            currentAlert.alertType
          )
        );
        return true;
      } catch (error) {
        addErrorMsg("launchAlert", error);
        return false;
      }
    }
    return false;
  }

  /**
   * Récupère l'état d'avancement des Bons Samaritains sur l'alerte en cours.
   * * @returns {boolean} Retourne si l'appel s'est bien passé.
   */
  async function onCallAlert() {
    if (!currentAlert.close && currentAlert.status === 2) {
      try {
        await dispatch(onCall(currentAlert.alertId));
        return true;
      } catch (error) {
        addErrorMsg("onCallAlert", error);
        return false;
      }
    }
    return false;
  }

  return (
    <DashboardLayout pageTitle={t("page.alert.title")}>
      {isDemoMode && (
        <Tutorial
          alertChangeHandler={(updates) => alertChangeHandler(updates)}
          launchAlert={launchAlert}
        />
      )}
      <SoftBox sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <SoftBox
          sx={{
            width: "400px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.16)",
          }}
        >
          <AlertFormLayout
            onChange={(updates) => alertChangeHandler(updates)}
            onSubmit={() => launchAlert()}
          />
          <CitizenResponders launchVideo={(responder) => onVideoLaunched(responder)} />
          <AlertBannerInProgress onClose={() => closeAlert()} onRefresh={() => onCallAlert()} />
        </SoftBox>
        <SoftBox sx={{ flexGrow: 1 }}>
          <Map
            onAddressChange={(updates) => alertChangeHandler(updates)}
            onClose={() => closeAlert()}
          />
        </SoftBox>
      </SoftBox>
      {videoPopin ? (
        <SoftBox position="absolute" width="620px" height="400px" bottom={245} right={10}>
          <VideoCard citizenResponder={videoPopin} closeVideo={() => onVideoCloseClicked()} />
        </SoftBox>
      ) : null}
      <TestBanner />
      <GenericErrorDialog />
      <AlertClosedDialog open={currentAlert.close ? true : false} />
      <AlertConfirmUpdateDialog
        open={potentialUpdates !== undefined}
        onClose={(answer) => confirmUpdatesAnswerHandler(answer)}
      />
    </DashboardLayout>
  );
}
