import config from "config.js";

import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import useOpenTok from "react-use-opentok";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import CallEndIcon from "@mui/icons-material/CallEnd";

// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import iconVideoOrange from "fdbs/assets/icons/iconVideoOrange.png";

import aedGrey from "fdbs/assets/icons/greyHeart.png";
import aedGreen from "fdbs/assets/icons/greenHeart.png";

// import iconAED from "fdbs/assets/icons/pinHexagonalSelected.png";

// import borders from "assets/theme/base/borders";

// import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import videoDefaultBackground from "fdbs/assets/images/background/videoDefaultBackground.jpg";

import { citizenResponderStatus } from "fdbs/layouts/dashboards/alert/tools";

// Translation components
import { useTranslation } from "react-i18next";

function VideoCard({ citizenResponder, closeVideo }) {
  const { t } = useTranslation();

  const [opentokProps, opentokMethods] = useOpenTok();

  const { initSessionAndConnect, publish, unpublish, subscribe, unsubscribe } = opentokMethods;

  const {
    // connection info
    isSessionInitialized,
    connectionId,
    isSessionConnected,

    // connected data
    session,
    connections,
    streams,
    subscribers,
    publisher,
  } = opentokProps;

  const videoConnection = {
    apiKey: config.API_VIDEO_KEY,
    sessionId: citizenResponder.videoSessionId,
    token: citizenResponder.videoToken,
  };

  const onStream = (stream) => {
    subscribe({
      stream,
      element: "subscriber",
      options: {
        insertMode: "replace",
        height: "420px",
      },
    });
  };

  const closeConnection = () => {
    unpublish({ name: "camera" });
    if (streams.length > 1) {
      const streamToUnsubscribe = streams[1];
      unsubscribe({ stream: streamToUnsubscribe });
    }
    closeVideo();
  };

  useEffect(() => {
    initSessionAndConnect(videoConnection);
    console.log("isSessionInitialized");
    console.log(isSessionInitialized);
    console.log(connectionId);
    console.log("isSessionConnected");
    console.log(isSessionConnected);
    console.log(session);
    console.log(connections);

    if (isSessionConnected) {
      publish({
        name: "camera",
        element: "me",
        options: {
          insertMode: "replace",
          width: "180px",
          height: "120px",
        },
      }).catch((ex) => {
        console.log("Error Publish");
        console.log(ex);
      });
    }

    console.log(subscribers);
    console.log(publisher);
  }, [initSessionAndConnect, isSessionConnected]);

  useEffect(() => {
    console.log("streams !!!!");
    console.log(streams.length);
    if (streams.length > 1) {
      const streamToSubscribe = streams[1];
      onStream(streamToSubscribe);
    }
  }, [streams]);

  const [statusBgColor, setStatusBgColor] = useState("error");
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    const resultStatus = citizenResponderStatus(citizenResponder.alertInfo.status);
    setStatusBgColor(resultStatus.bgColor);
    setStatusText(t(resultStatus.tagText));
  }, [citizenResponder.alertInfo.status]);

  return (
    <Card sx={{ marginTop: "10px" }} overflow="auto">
      <SoftBox p={0} bgColor="secondary" backgroundGradient>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
              <SoftBox display="flex">
                <SoftBox position="relative">
                  <Icon className="" fontSize="small">
                    <img src={iconVideoOrange} width="100%" alt="hello" />
                  </Icon>{" "}
                </SoftBox>
                <SoftBox px={2}>
                  <SoftTypography component="p" variant="button" fontWeight="medium" color="white">
                    {citizenResponder.name}{" "}
                    <span className="text-xxs">
                      {t("alert.crCard.age", { age: citizenResponder.age })}
                    </span>
                  </SoftTypography>
                  <SoftTypography component="p" variant="caption" color="white">
                    {citizenResponder.training === "UNTRAINED"
                      ? t("alert.crCard.untrained")
                      : citizenResponder.skill}
                  </SoftTypography>
                  <SoftTypography component="p" variant="caption" color="white">
                    {citizenResponder.skillSpeciality}
                  </SoftTypography>
                  <SoftTypography component="p" variant="caption" color="white" gutterBottom>
                    {t("alert.crCard.phone")} {citizenResponder.phone}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox
                  component="img"
                  src={citizenResponder.alertInfo.AED === 1 ? aedGreen : aedGrey}
                  alt="AED"
                  height="20px"
                  mr={0}
                />
                <SoftBadge
                  color={statusBgColor}
                  size="xs"
                  badgeContent={statusText}
                  variant="insided"
                />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <SoftBox p={0} m={0} backgroundColor="white" className="line-height-0">
              <div id="subscriber" height="420px">
                <SoftBox
                  component="img"
                  src={videoDefaultBackground}
                  height="420px"
                  width="620px"
                />
              </div>
              <div id="me" style={{ position: "absolute", top: "126px", right: "5px" }} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <SoftBox display="flex" alignItems="center" justifyContent="center" m={2}>
              <SoftButton size="large" circular iconOnly color="white" variant="outlined">
                <CallEndIcon className="" color="white" onClick={() => closeConnection()} />
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of AnnouncementCard
VideoCard.defaultProps = {
  citizenResponder: { alertInfo: [] },
  closeVideo: PropTypes.func,
};

VideoCard.propTypes = {
  citizenResponder: PropTypes.shape({
    citizenResponderId: PropTypes.number,
    number: PropTypes.number,
    appId: PropTypes.number,
    name: PropTypes.string,
    skill: PropTypes.string,
    skillSpeciality: PropTypes.string,
    phone: PropTypes.string,
    age: PropTypes.number,
    certificated: PropTypes.bool,
    training: PropTypes.string,
    contact: PropTypes.bool,
    videoSessionId: PropTypes.string,
    videoToken: PropTypes.string,
    alertInfo: PropTypes.arrayOf(PropTypes.object),
  }),
  closeVideo: PropTypes.func,
};

export default VideoCard;
