import { useTranslation } from "react-i18next";
import i18n from "i18n";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import HeatMap from "./HeatMap/HeatMap";

export default function HeatmapDialog({ data, open, onClose, title = "" }) {
  const { t } = useTranslation();
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  return (
    <Dialog open={open} maxWidth={false} fullWidth>
      <DialogTitle sx={{ textAlign: "center", padding: "35px 24px 16px 24px" }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "35px 24px", textAlign: "center" }}>
        {data && Object.entries(data).length > 0 ? (
          <>
            <DialogContentText
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <AutorenewIcon fontSize="medium" sx={{ marginRight: "0.25rem" }} />{" "}
              {t("dashboard.dialog.heatmap.date", {
                dateNow: new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                  dateStyle: "short",
                }).format(Date.now()),
                timeNow: new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                  timeStyle: "short",
                }).format(Date.now()),
              })}
            </DialogContentText>
            <HeatMap center={data.center} zoom={data.zoom} dataset={data.dataset} />
          </>
        ) : (
          <SoftBox sx={{ textAlign: "center" }} my={5}>
            <CircularProgress />
          </SoftBox>
        )}
        <SoftButton
          variant="outlined"
          color="dark"
          sx={{ marginTop: "2.25rem" }}
          onClick={() => onClose()}
        >
          {t("dashboard.dialog.heatmap.button")}
        </SoftButton>
      </DialogContent>
    </Dialog>
  );
}
