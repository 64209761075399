/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// import { Link } from "react-router-dom";
import { useState } from "react";
import config from "config.js";
import { useSelector } from "react-redux";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import { aedService } from "services";

// @mui material components
import Card from "@mui/material/Card";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import iconAED from "fdbs/assets/icons/pinHexagonalSelected.png";

import borders from "assets/theme/base/borders";

import Icon from "@mui/material/Icon";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

// Translation components
import { useTranslation } from "react-i18next";
import SoftButton from "components/SoftButton";

// import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
// import SuiButton from "components/SuiButton";

// Custom styles for AnnouncementCard
// import style from "examples/Cards/AnnouncementCard/style";

function AedCard({ aed }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.userState);

  // const classes = style();
  const { borderWidth, borderColor } = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const callPhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  function formatPhoneText(phone) {
    //const phoneCleaned = phone.replace(/\s/g, "$1");
    //completePhoneNumber = phoneCleaned.replace(/(.{2})/g, "$1 ");
    //const [countryCode, phoneNumber] = phone.split(" ");
    // let completePhoneNumber;
    // switch (countryCode) {
    //   case "+33":
    //     completePhoneNumber = "0" + phoneNumber;
    //     // RegExp pratique qui ajoute 1 espace tous les 2 caractères
    //     completePhoneNumber = completePhoneNumber.replace(/(.{2})/g, "$1 ");
    //     break;
    //   default:
    //     completePhoneNumber = phone.replace(/(.{2})/g, "$1 ");
    // }
    return phone;
  }

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const reportProblemHandler = (selectedElmt) => {
    const problemId = selectedElmt.currentTarget.dataset.id;
    aedService.reportProblem(aed.id, problemId, currentUser.user.email).then(
      () => {
        handleCloseMenu();
      },
      () => {
        handleCloseMenu();
      }
    );
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <SoftTypography variant="button" color="text">
        {t("alert.aed.menu.problem-type.title")}
      </SoftTypography>
      <MenuItem data-id="1" onClick={reportProblemHandler}>
        {" "}
        {t("alert.aed.menu.problem-type.missing")}
      </MenuItem>
      <MenuItem data-id="2" onClick={reportProblemHandler}>
        {" "}
        {t("alert.aed.menu.problem-type.out-of-order")}
      </MenuItem>
      <MenuItem data-id="3" onClick={reportProblemHandler}>
        {" "}
        {t("alert.aed.menu.problem-type.poorly-positioned")}
      </MenuItem>
      <MenuItem data-id="5" onClick={reportProblemHandler}>
        {" "}
        {t("alert.aed.menu.problem-type.duplicate")}
      </MenuItem>
    </Menu>
  );

  const streetViewImg = `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${aed.lat},${aed.lon}&fov=80&heading=120&pitch=0&key=${config.GMAP_KEY}`;
  const urlImgPath = config.MEDIA_URL;

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
        <SoftBox display="flex" mr={2}>
          <SoftBox
            component="img"
            src={iconAED}
            alt="aed"
            width="10%"
            height="10%"
            opacity={0.9}
            mt={1}
            mb={1}
          />
          <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
            {aed.name ? (
              <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {aed.name}
              </SoftTypography>
            ) : null}
            {aed.address ? (
              <SoftTypography variant="caption" color="text">
                {aed.address}
              </SoftTypography>
            ) : null}
            {aed.address2 ? (
              <SoftTypography variant="caption" color="text">
                {aed.address2}
              </SoftTypography>
            ) : null}
            {aed.zipCode || aed.city ? (
              <SoftTypography variant="caption" color="text">
                {aed.zipCode ? `${aed.zipCode} ` : null}
                {aed.city}
              </SoftTypography>
            ) : null}
            {aed.floor || aed.building ? (
              <SoftTypography variant="caption" color="text">
                {aed.floor ? `${t("alert.aed.floor")}${aed.floor} ` : null}
                {aed.building ? `${t("alert.aed.building")}${aed.building} ` : null}
              </SoftTypography>
            ) : null}
            {aed.phoneNumber && aed.phoneNumber !== null ? (
              <SoftTypography variant="caption" sx={{ paddingTop: "0.25rem" }}>
                {formatPhoneText(aed.phoneNumber)}
              </SoftTypography>
            ) : null}
          </SoftBox>
        </SoftBox>
        <SoftBox ml="auto" color="secondary" pr={0} lineHeight={0}>
          <Icon
            className="material-ui-icons cursor-pointer"
            fontSize="default"
            onClick={handleOpenMenu}
          >
            more_vert
          </Icon>
        </SoftBox>
        {renderMenu()}
      </SoftBox>
      <SoftBox pt={0.5} pb={2} px={2}>
        {aed.description ? (
          <SoftBox ml={0} mt={1} mb={2} lineHeight={0}>
            <SoftTypography variant="caption" fontWeight="regular" color="text">
              {aed.description}
            </SoftTypography>
          </SoftBox>
        ) : null}
        <Divider />
        <SoftBox mt={0} pt={0}>
          <Grid container spacing={1}>
            {aed.medias &&
              aed.medias.map((image, index) => (
                <Grid item xs={3} key={index}>
                  <Zoom zoomMargin={50}>
                    <SoftBox
                      component="img"
                      id={1}
                      src={`${urlImgPath}${image.filename}`}
                      alt="small image 2"
                      borderRadius="lg"
                      boxShadow="regular"
                      width="100%"
                      sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                    />
                  </Zoom>
                </Grid>
              ))}
            <Grid item xs={3}>
              <Zoom zoomMargin={50}>
                <SoftBox
                  component="img"
                  id={0}
                  src={streetViewImg}
                  alt="small image 1"
                  borderRadius="lg"
                  boxShadow="regular"
                  width="100%"
                  sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                />
              </Zoom>
            </Grid>
          </Grid>
        </SoftBox>
        <Divider />
        {aed.position > 0 ? (
          <>
            <SoftBadge
              color="secondary"
              size="xs"
              badgeContent={t(`alert.aed.badge.position.${aed.position}`)}
              container
            />{" "}
          </>
        ) : null}
        {aed.accessibility > 0 ? (
          <>
            <SoftBadge
              color="secondary"
              size="xs"
              badgeContent={t(`alert.aed.badge.accessibility.${aed.accessibility}`)}
              container
            />{" "}
          </>
        ) : null}
        {/* <SoftBadge color="error" size="xs" badgeContent="Fermé" container />{" "} */}
        {aed.alwaysOpened === 1 ? (
          <>
            <SoftBadge
              color="success"
              size="xs"
              badgeContent={t("alert.aed.badge.alwaysOpened")}
              container
            />{" "}
          </>
        ) : null}
        {aed.validate > 0 ? (
          <>
            <SoftBadge
              color="success"
              size="xs"
              badgeContent={t("alert.aed.badge.valid")}
              container
            />{" "}
          </>
        ) : null}
        {aed.phoneNumber && aed.phoneNumber !== null ? (
          <SoftBox
            pt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              "& svg": { fontSize: "1.3rem!important", marginRight: "0.625rem" },
              "& button": { padding: "0.5rem", marginX: "0.5rem" },
            }}
          >
            <SoftButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => callPhone(aed.phoneNumber)}
            >
              <LocalPhoneOutlinedIcon /> {t("alert.aed.button.phone")}
            </SoftButton>
          </SoftBox>
        ) : null}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of AnnouncementCard
AedCard.defaultProps = {
  aed: {
    medias: [],
  },
};

// Typechecking props for the AnnouncementCard
AedCard.propTypes = {
  aed: PropTypes.shape({
    medias: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    lon: PropTypes.number,
    lat: PropTypes.number,
    floor: PropTypes.string,
    building: PropTypes.string,
    description: PropTypes.string,
    phoneNumber: PropTypes.string,
    validate: PropTypes.number,
    alwaysOpened: PropTypes.number,
    position: PropTypes.number, // 1: inside, 2 :outside
    accessibility: PropTypes.number, // 1: private, 2 :public
  }),
};

export default AedCard;
