import pinMapBlue from "fdbs/assets/icons/pin_map_blue.svg";
import pinMapGreen from "fdbs/assets/icons/pin_map_green.svg";
import pinMapGrey from "fdbs/assets/icons/pin_map_grey.svg";
import pinMapOrange from "fdbs/assets/icons/pin_map_orange.svg";
import pinMapRed from "fdbs/assets/icons/pin_map_red.svg";

export function citizenResponderStatus(status) {
  const result = { bgColor: "light", color: "#868C9A", tagText: "alert.crCard.status.unavailable" };

  switch (status) {
    case "UNAVAILABLE":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.unavailable";
      break;
    case "STOPPED":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.stopped";
      break;
    case "CANCELLED":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.cancelled";
      break;
    case "TO_FAR":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.too-far";
      break;
    case "REFUSE":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.refuse";
      break;
    case "WAITING_TRAINED":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.waiting-trained";
      break;
    case "WITHOUT_AED_CANCEL":
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.without-aed-cancel";
      break;
    case "AVAILABLE":
      result.bgColor = "info";
      result.color = "#50ADBC";
      result.tagText = "alert.crCard.status.available";
      break;
    case "DISPATCHED":
      result.bgColor = "info";
      result.color = "#50ADBC";
      result.tagText = "alert.crCard.status.dispatched";
      break;
    case "ON_THE_WAY_AED":
      result.bgColor = "success";
      result.color = "#5DB27E";
      result.tagText = "alert.crCard.status.on-the-way-aed";
      break;
    case "ON_SITE_AED":
      result.bgColor = "success";
      result.color = "#5DB27E";
      result.tagText = "alert.crCard.status.on-site-aed";
      break;
    case "ON_THE_WAY_VICTIM":
      result.bgColor = "warning";
      result.color = "#EDBF4B";
      result.tagText = "alert.crCard.status.on-the-way-victim";
      break;
    case "ON_SITE_VICTIM":
      result.bgColor = "error";
      result.color = "#C3291C";
      result.tagText = "alert.crCard.status.on-site-victim";
      break;
    default:
      result.bgColor = "light";
      result.color = "#868C9A";
      result.tagText = "alert.crCard.status.unavailable";
  }
  return result;
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function getDistance(lon1, lat1, lon2, lat2) {
  let earthRadiusKm = 6371;
  let dLat = degreesToRadians(lat2 - lat1);
  let dLon = degreesToRadians(lon2 - lon1);
  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = earthRadiusKm * c;
  return distance;
}
