import { Icon, IconButton } from "@mui/material";
import SoftBox from "components/SoftBox";
import { useSoftUIController, setOpenConfigurator } from "context";

import UserCard from "fdbs/components/Cards/UserCard/UserCard";

export default function SideMenuButton() {
  const [controller, dispatch, openConfigurator] = useSoftUIController();

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  return (
    <SoftBox
      onClick={handleConfiguratorOpen}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        width: "250px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <IconButton size="large" color="inherit">
        <Icon fontSize="large">menu</Icon>
      </IconButton>
      <UserCard />
    </SoftBox>
  );
}
