import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { getAlert } from "actions/alertAction";
import useErrorFormater from "./useErrorFormater";
import { unloadAlert } from "actions/alertAction";

export default function useGetAlertFromURL() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const urlAlertId = searchParams.get("alertId");
  const [addErrorMsg] = useErrorFormater();

  useEffect(() => {
    if (urlAlertId) {
      dispatch(unloadAlert());
      dispatch(getAlert(urlAlertId)).catch((error) => addErrorMsg("getAlert", error));
    } else {
      dispatch(unloadAlert());
    }
  }, [urlAlertId, location.key]);
}
