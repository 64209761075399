import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function HelperText({ text = "", color = "error" }) {
  return (
    <SoftBox pt={0.25} sx={{ display: "flex" }}>
      <InfoOutlinedIcon
        sx={{ verticalAlign: "bottom", marginTop: "2px", marginRight: "5px" }}
        color={color}
      />
      <SoftTypography
        color={color}
        sx={{
          flexGrow: "1",
          paddingY: "5px",
          fontSize: "0.75rem",
          "& svg": { fontSize: "1rem!important" },
        }}
      >
        {text}
      </SoftTypography>
    </SoftBox>
  );
}
