/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function FDBSReportsDoughnutChartItem({ color, title, value, percentage }) {
  return (
    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
      <SoftBox
        flexShrink={0}
        mr={1.125}
        sx={{ backgroundColor: color, width: "1.25rem", height: "1.25rem", borderRadius: "4px" }}
      />
      <SoftTypography variant="caption" sx={{ marginRight: "0.5rem", flexGrow: "1" }}>
        {title}
      </SoftTypography>
      <SoftTypography
        variant="caption"
        sx={{ color: (theme) => theme.palette.grey[500], flexShrink: "0" }}
      >
        {percentage}% ({value})
      </SoftTypography>
    </SoftBox>
  );
}

// Setting default values for the props of ReportsDoughnutChartItem
FDBSReportsDoughnutChartItem.defaultProps = {
  color: "info",
  hasBorder: false,
};

// Typechecking props for the ReportsDoughnutChartItem
FDBSReportsDoughnutChartItem.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default FDBSReportsDoughnutChartItem;
