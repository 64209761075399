import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAlertOption } from "actions/alertAction";
import useErrorFormater from "./useErrorFormater";

export default function useGetAlertOptions() {
  const dispatch = useDispatch();
  const [addErrorMsg] = useErrorFormater();

  useEffect(() => {
    dispatch(getAlertOption()).catch((error) => addErrorMsg("loadOptions", error));
  }, []);
}
