import { useTranslation } from "react-i18next";
import i18n from "i18n";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import FDBSReportsDoughnutChart from "fdbs/components/DashboardPage/FDBSReportsDoughnutChart";
import { doughnutColors } from "constants";
import useGetCitizensRespondersSkills from "hooks/indicators/useGetCitizensRespondersSkills";
import { CircularProgress } from "@mui/material";

export default function CitizensRespondersSkillsCard({ filter = { area: "" } }) {
  const { t } = useTranslation();
  const statsSkills = useGetCitizensRespondersSkills({
    area: filter.area,
  });
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  return (
    <SoftBox
      p={3}
      sx={{
        backgroundColor: (theme) => theme.palette.white.main,
        borderRadius: "16px",
        boxShadow: "0px 2px 7px rgba(56, 71, 100, 0.3)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SoftTypography variant="h5">
        {t("dashboard.monitoringCitizensResponders.skills.title")}
      </SoftTypography>
      {statsSkills && Object.entries(statsSkills).length > 0 ? (
        <>
          <SoftTypography variant="body2">
            {t("dashboard.monitoringCitizensResponders.skills.date", {
              dateNow: new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                dateStyle: "short",
              }).format(Date.now()),
              timeNow: new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                timeStyle: "short",
              }).format(Date.now()),
            })}
          </SoftTypography>
          <FDBSReportsDoughnutChart
            count={{
              number: statsSkills.datasets.reduce((a, b) => a + b, 0),
              text: t("dashboard.monitoringCitizensResponders.skills.countText"),
            }}
            chart={{
              labels: statsSkills.labels,
              datasets: {
                backgroundColors: Object.values(doughnutColors),
                data: statsSkills.datasets,
              },
            }}
          />
        </>
      ) : (
        <SoftBox sx={{ textAlign: "center" }} my={5}>
          <CircularProgress />
        </SoftBox>
      )}
    </SoftBox>
  );
}
