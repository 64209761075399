import API from "helpers/api";
import { authHeader } from "../helpers/authHeader";

const notification = async (tag, lang) => {
  const path = `/services/backOffice/notification/?lang=${lang}&tag=${tag}`;
  const res = await API.get(path, { headers: authHeader() });
  const dt = res.data.data;
  localStorage.setItem("notification", JSON.stringify(dt));

  return dt;
};

export const notificationService = {
  notification,
};
