export const alertConstants = {
  OPTION_REQUEST: "OPTION_REQUEST",
  OPTION_SUCCESS: "OPTION_SUCCESS",
  OPTION_ERROR: "OPTION_ERROR",
  SETTINGUP_SUCCESS: "SETTINGUP_SUCCESS",
  SETTINGUP_ERROR: "SETTINGUP_ERROR",
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_ERROR: "ALERT_ERROR",
  UPDT_SUCCESS: "UPDT_SUCCESS",
  UPDT_ERROR: "UPDT_ERROR",
  ONCALL_SUCCESS: "ONCALL_SUCCESS",
  ONCALL_ERROR: "ONCALL_FAILURE",
  CLOSE_SUCCESS: "CLOSE_SUCCESS",
  CLOSE_ERROR: "CLOSE_ERROR",
  STOPPED_SUCCESS: "STOPPED_SUCCESS",
  STARTED_SUCCESS: "STARTED_SUCCESS",
  POPULATE_SUCCESS: "POPULATE_SUCCESS",
  POPULATE_AED_SELECTED_SUCCESS: "POPULATE_AED_SELECTED_SUCCESS",
  COUNTRY_SELECTED_SUCCESS: "COUNTRY_SELECTED_SUCCESS",
  WRONG_ADDRESS: "WRONG_ADDRESS",
};

export const fakeAlertResult = {
  alert: {
    alertId: 1,
    alertType: 1,
    idSite: 1,
    address: "3 Rue de Londres, 75009 Paris",
    addressInfo: "",
    lat: 48.87647150000001,
    lon: 2.330558,
    distanceMax: 0.4,
    nbRequests: 0,
    status: 2,
    close: 0,
    creationDate: "2023-05-12T13:26:29.000Z",
  },
};

export const fakeSettingUpResult = {
  alert: {
    alertId: 1,
    alertType: 1,
    idSite: 1,
    address: "3 Rue de Londres, 75009 Paris",
    addressInfo: "",
    lat: 48.87647150000001,
    lon: 2.330558,
    distanceMax: 0.4,
    nbPotentialBS: 0,
    status: 1,
    creationDate: "2023-05-12T12:37:11.862Z",
  },
  aed: [
    {
      id: 4909,
      idAed: 4879,
      idSite: 47,
      name: "Centre des Finances Publiques",
      address: "5 rue de Londres",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.330239,
      lat: 48.876645,
      alt: 0,
      description: null,
      sourceInfo: null,
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891574.5143,
      updated: 1493891574.5143,
      DISTANCE: 0.03027260245761059,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 111369,
      idAed: 108732,
      idSite: 47,
      name: "à l'entrée à droite",
      address: "2 Rue de Londres",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.33061,
      lat: 48.876742,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "Android", "mobileType": "android"}, "origin": "mobileapp"}',
      status: "ADD",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1511613362.5303,
      updated: 1511613362.5303,
      DISTANCE: 0.030317524605714585,
      medias: [],
      phoneNumber: "0102030405 ",
    },
    {
      id: 10464,
      idAed: 10434,
      idSite: 47,
      name: "FONCIA",
      address: "70, rue Saint Lazare",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.330857,
      lat: 48.876209,
      alt: 0,
      description: null,
      sourceInfo:
        '{"info": {"source": "idiphone-1.5-726ade334dd8f3262072f81b4ca27fb49e008704", "mobileType": "iphone"}, "origin": "mobileapp"}',
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891618.0797,
      updated: 1493891618.0797,
      DISTANCE: 0.03647060285439174,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 82682,
      idAed: 80804,
      idSite: 47,
      name: "CNB",
      address: "32 Rue de Mogador ",
      address2: "conseil national des barreaux ",
      zipCode: "75009",
      state: "en fonction ",
      city: "Paris",
      country: "FR",
      lon: 2.331287,
      lat: 48.875864,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "Android", "mobileType": "android"}, "origin": "mobileapp"}',
      status: "ADD",
      validate: 2,
      building: null,
      floor: "5 ",
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1458654375.1011,
      updated: 1458654375.1011,
      DISTANCE: 0.08605464532130135,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 4912,
      idAed: 4884,
      idSite: 47,
      name: "Pharmacie europenne de la place de clichy",
      address: "6 place de clichy",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.33065,
      lat: 48.877293,
      alt: 0,
      description: null,
      sourceInfo:
        '{"info": {"source": "idiphone-1.1-84f0a15a578a149e7f01c5ed6e36796282644cfb", "mobileType": "iphone"}, "origin": "mobileapp"}',
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 1,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891683.0228,
      updated: 1493891683.0228,
      DISTANCE: 0.09159406550380267,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 59677,
      idAed: 57810,
      idSite: 47,
      name: "Google France",
      address: "8, Rue de Londres",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.330037,
      lat: 48.877352,
      alt: 0,
      description: "Ouverture par badge 9h00 20h00",
      sourceInfo: null,
      status: "Upd",
      validate: 2,
      building: null,
      floor: "2",
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1493891515.6909,
      updated: 1493891515.6909,
      DISTANCE: 0.10505949455302611,
      medias: [
        {
          id: 1582,
          type: "image/jpeg",
          filename: "1418036811Photo.png",
        },
      ],
      phoneNumber: "",
    },
    {
      id: 40385,
      idAed: 40350,
      idSite: 47,
      name: "ITN",
      address: "82 rue saint lazare",
      address2: "bat A",
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.329361,
      lat: 48.875889,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "Upd",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891645.866,
      updated: 1493891645.866,
      DISTANCE: 0.10889579413840113,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 65334,
      idAed: 63456,
      idSite: 47,
      name: "EON",
      address: "5 rue d'Athènes",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.329371,
      lat: 48.877822,
      alt: 0,
      description: "E ON",
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "Upd",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1493891592.814,
      updated: 1493891592.814,
      DISTANCE: 0.1734527389815751,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 7805,
      idAed: 7774,
      idSite: 47,
      name: "Spie Sud Ouest Dria",
      address: "92  Rue Saint-Lazare",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.32825,
      lat: 48.875842,
      alt: 0,
      description: null,
      sourceInfo: null,
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 0,
      accessibility: 0,
      saveLife: 0,
      created: 1493891703.4809,
      updated: 1493891703.4809,
      DISTANCE: 0.1827264542767061,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 52642,
      idAed: 50776,
      idSite: 47,
      name: "Sony",
      address: "54 rue de Chateaudun",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.333195,
      lat: 48.876578,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "Upd",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891703.2159,
      updated: 1493891703.2159,
      DISTANCE: 0.1932100084710245,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 99868,
      idAed: 97379,
      idSite: 47,
      name: "MNT",
      address: "4 Rue d'Athènes",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.329535,
      lat: 48.878125,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "Upd",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1493891670.037,
      updated: 1493891670.037,
      DISTANCE: 0.19849844807328168,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 60424,
      idAed: 58557,
      idSite: 47,
      name: "Casino de Paris",
      address: "25 Rue de Clichy",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.330147,
      lat: 48.878321,
      alt: 0,
      description: null,
      sourceInfo: null,
      status: "Upd",
      validate: 2,
      building: null,
      floor: "RdC",
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1493891530.3072,
      updated: 1493891530.3072,
      DISTANCE: 0.20783979913643658,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 122475,
      idAed: 119839,
      idSite: 47,
      name: "moulin rouge",
      address: "82 boulevard de Clichy ",
      address2: "Moulin rouge",
      zipCode: "75009",
      state: "Île-de-France",
      city: "Paris",
      country: "FR",
      lon: 2.333542,
      lat: 48.875931,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "ADD",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 1,
      position: 1,
      accessibility: 1,
      saveLife: 0,
      created: 1520193854.242,
      updated: 1520193854.242,
      DISTANCE: 0.2263495625783875,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 39943,
      idAed: 39913,
      idSite: 47,
      name: "solvay",
      address: "25 rue de clichy",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.329992,
      lat: 48.878501,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "iPhone"}, "origin": "backoffice"}',
      status: "Upd",
      validate: 2,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891703.0386,
      updated: 1493891703.0386,
      DISTANCE: 0.22943463463017846,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 10462,
      idAed: 10432,
      idSite: 47,
      name: "Autorité de Contrôle Prudentiel et de Résolution",
      address: "53 rue chateaudun",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.333689,
      lat: 48.876198,
      alt: 0,
      description: null,
      sourceInfo: null,
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 0,
      accessibility: 0,
      saveLife: 0,
      created: 1493891561.7331,
      updated: 1493891561.7331,
      DISTANCE: 0.23098516920031562,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 208295,
      idAed: null,
      idSite: 1120,
      name: "DAE TELFRANCE",
      address: "71 rue de la Victoire",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.33315,
      lat: 48.8752,
      alt: null,
      description: "En entrant à droite",
      sourceInfo: '{"info": {"exptRais": "NEWEN TELFRANCE SERIES MARSEILLE "}, "origin": "IMPORT"}',
      status: "ADD",
      validate: 0,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 1,
      saveLife: null,
      created: null,
      updated: null,
      DISTANCE: 0.23647820576038434,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 16129,
      idAed: 16099,
      idSite: 47,
      name: "Sapeurs Pompiers",
      address: "24 rue blanche",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.331744,
      lat: 48.878512,
      alt: 0,
      description: null,
      sourceInfo: null,
      status: "Upd",
      validate: 1,
      building: null,
      floor: null,
      alwaysOpened: 1,
      position: 1,
      accessibility: 0,
      saveLife: 0,
      created: 1493891697.6445,
      updated: 1493891697.6445,
      DISTANCE: 0.2429052573030352,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 209294,
      idAed: null,
      idSite: 1120,
      name: "DAE-VO2 Group",
      address: "58 bis 58 bis Rue de la Chaussée d'Antin 75009 Paris",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.33264,
      lat: 48.8747,
      alt: null,
      description: "",
      sourceInfo: '{"info": {"exptRais": "Lifeaz"}, "origin": "IMPORT"}',
      status: "ADD",
      validate: 0,
      building: null,
      floor: null,
      alwaysOpened: 0,
      position: 1,
      accessibility: 2,
      saveLife: null,
      created: null,
      updated: null,
      DISTANCE: 0.24896890041342729,
      medias: [],
      phoneNumber: "",
    },
    {
      id: 121736,
      idAed: 119100,
      idSite: 47,
      name: "Bâtiment public MDPH de Paris",
      address: "69 rue de la Victoire",
      address2: "MDPH 75",
      zipCode: "75009",
      state: "75",
      city: "Paris",
      country: "FR",
      lon: 2.333364,
      lat: 48.875117,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "Android", "mobileType": "android"}, "origin": "mobileapp"}',
      status: "ADD",
      validate: 2,
      building: "Public",
      floor: "RDC",
      alwaysOpened: 0,
      position: 1,
      accessibility: 2,
      saveLife: 0,
      created: 1519631935.4389,
      updated: 1519631935.4389,
      DISTANCE: 0.25454888693247374,
      medias: [
        {
          id: 3125,
          type: "image/jpeg",
          filename: "15196319351519631871655.jpg",
        },
      ],
      phoneNumber: "",
    },
    {
      id: 100938,
      idAed: 98449,
      idSite: 47,
      name: "VFLI",
      address: "6 rue d'Amsterdam 75009 Paris",
      address2: null,
      zipCode: "75009",
      state: null,
      city: "Paris",
      country: "FR",
      lon: 2.327088,
      lat: 48.876039,
      alt: 0,
      description: null,
      sourceInfo: '{"info": {"source": "Android", "mobileType": "android"}, "origin": "mobileapp"}',
      status: "Upd",
      validate: 2,
      building: "Vfli",
      floor: "5",
      alwaysOpened: 1,
      position: 0,
      accessibility: 1,
      saveLife: 0,
      created: 1493891523.4139,
      updated: 1493891523.4139,
      DISTANCE: 0.25828312886300064,
      medias: [],
      phoneNumber: "",
    },
  ],
};

export const fakeBSPositions = [
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          alertInfo: {
            lon: "2.324285028844045",
            lat: "48.87643771565772",
            AED: 0,
            status: "DISPATCHED",
            distance: 739,
            duration: 231,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          alertInfo: {
            lon: "2.3300650526804967",
            lat: "48.87478870689667",
            AED: 0,
            status: "DISPATCHED",
            distance: 337,
            duration: 126,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          alertInfo: {
            lon: "2.3361366999972284",
            lat: "48.87560504060538",
            AED: 0,
            status: "DISPATCHED",
            distance: 570,
            duration: 165,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32371",
            lat: "48.87624",
            AED: 0,
            status: "ON_THE_WAY_AED",
            distance: 739,
            duration: 231,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33006",
            lat: "48.8747",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 337,
            duration: 126,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33609",
            lat: "48.87545",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 570,
            duration: 165,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32425",
            lat: "48.87581",
            AED: 0,
            status: "ON_THE_WAY_AED",
            distance: 669,
            duration: 210,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33045",
            lat: "48.87478",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 319,
            duration: 108,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33527",
            lat: "48.8753",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 953,
            duration: 279,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32456",
            lat: "48.87606",
            AED: 0,
            status: "ON_THE_WAY_AED",
            distance: 684,
            duration: 215,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33098",
            lat: "48.87621",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 59,
            duration: 18,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33308",
            lat: "48.87529",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 325,
            duration: 99,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32564",
            lat: "48.8762",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 462,
            duration: 132,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33098",
            lat: "48.87621",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 59,
            duration: 18,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33235",
            lat: "48.87531",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 267,
            duration: 82,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32594",
            lat: "48.87628",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 1067,
            duration: 354,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33193",
            lat: "48.87594",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 340,
            duration: 109,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32625",
            lat: "48.87633",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 1079,
            duration: 359,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33183",
            lat: "48.87615",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 368,
            duration: 116,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32684",
            lat: "48.87711",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 1169,
            duration: 391,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33105",
            lat: "48.87605",
            AED: 0,
            status: "ON_THE_WAY_VICTIM",
            distance: 79,
            duration: 27,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.32704",
            lat: "48.87745",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 1206,
            duration: 405,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.3292",
            lat: "48.87707",
            AED: 1,
            status: "ON_THE_WAY_VICTIM",
            distance: 1328,
            duration: 395,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
      ],
    },
  },
  {
    error: false,
    data: {
      citizenResponder: [
        {
          citizenResponderId: 53301,
          number: 1,
          name: "Aaron BOT",
          age: 25,
          phone: "+33 000001509",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Sapeurs-Pompiers",
          skillSpeciality: "SPP",
          contact: false,
          videoSessionId:
            "1_MX40NjI3MTUzMn5-MTY4NDEzNTcxMDkyMH42dzZNbU9TSEpDMkxvemVFRnNwcXlrMGh-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9M2Y2YjMzZTRlNDNkNmVlYzRmYTM2OGY1Y2ZmMjRhYzc2Y2NhZTk1MjpzZXNzaW9uX2lkPTFfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1Ea3lNSDQyZHpaTmJVOVRTRXBETWt4dmVtVkZSbk53Y1hsck1HaC1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC43ODQxNDk1MzIwODg1MjAxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 1,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53302,
          number: 2,
          name: "Bill BOT",
          age: 25,
          phone: "+33 000001758",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Secouriste",
          skillSpeciality: "PSE2/AFGSU2",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA4M345MTdkVWJlWi9vRHpHcjh2RXF1ZnMrYzF-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9ZTI4Nzc1ZGNmZGRkNjNjODE3NmY1MzhhNTVjYjU3YTc1OTc3YjdkYjpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTRNMzQ1TVRka1ZXSmxXaTl2UkhwSGNqaDJSWEYxWm5Ncll6Ri1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC4xNjIyNDQyNjY2NzkwNTYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY4NDIyMjExMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
        {
          citizenResponderId: 53303,
          number: 3,
          name: "Arthur BOT",
          age: 25,
          phone: "+33 000004314",
          app: "Staying Alive",
          training: "TRAINED",
          certificated: false,
          skill: "Volontaire formé",
          skillSpeciality: "PSC1",
          contact: false,
          videoSessionId:
            "2_MX40NjI3MTUzMn5-MTY4NDEzNTcxMTA1Nn5GVWhFTUluVzhYL05yQXhXWmZKbmFsYVJ-UH5-",
          videoToken:
            "T1==cGFydG5lcl9pZD00NjI3MTUzMiZzaWc9NTcxZDcxZjlkNGZjZjQ5ZWFkMDMwMjU1MmFmNTI1OTBjMDUwOWY0ODpzZXNzaW9uX2lkPTJfTVg0ME5qSTNNVFV6TW41LU1UWTROREV6TlRjeE1UQTFObjVHVldoRlRVbHVWemhZTDA1eVFYaFhXbVpLYm1Gc1lWSi1VSDUtJmNyZWF0ZV90aW1lPTE2ODQxMzU3MTEmbm9uY2U9MC40MzA5MDQwODMyMTYwMzM2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2ODQyMjIxMTEmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
          alertInfo: {
            lon: "2.33065",
            lat: "48.8766",
            AED: 0,
            status: "ON_SITE_VICTIM",
            distance: 0,
            duration: 0,
          },
        },
      ],
    },
  },
];
