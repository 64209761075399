// /**
// =========================================================
// * Soft UI Dashboard PRO React - v2.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// /**
//   All of the routes for the Soft UI Dashboard PRO Material are added here,
//   You can add a new route, customize the routes and delete the routes here.

//   Once you add a new route on this file it will be visible automatically on
//   the Sidenav.

//   For adding a new route you can follow the existing routes in the routes array.

//   1. The `type` key with the `collapse` value is used for a route.
//   2. The `type` key with the `title` value is used for a title inside the Sidenav.
//   3. The `type` key with the `divider` value is used for a divider between Sidenav items.
//   4. The `type` key with the `single` value is used for route without collpase.
//   5. The `type` key with the `hidden` value is used for hide items.

//   4. The `name` key is used for the name of the route on the Sidenav.
//   5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
//   6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
//   7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
//   inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
//   8. The `route` key is used to store the route location which is used for the react router.
//   9. The `href` key is used to store the external links location.
//   10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
//   10. The `component` key is used to store the component of its route.
// */

// // Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import {
  AlertPageWithActions as Alert,
  AlertPageWithDemoActions as AlertDemo,
} from "fdbs/layouts/dashboards/alert/withActions";
import SignIn from "fdbs/layouts/authentication/sign-in";
import ResetPwd from "fdbs/layouts/authentication/reset-password";
import RenewPwd from "fdbs/layouts/authentication/renew-password";
import UpdtPwd from "fdbs/layouts/authentication/updt-password";
import Error500 from "fdbs/layouts/authentication/error/500";
import DashboardPage from "fdbs/components/DashboardPage/DashboardPage";

import Heart from "fdbs/Icons/Heart";

const adminRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <Alert />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/tutorial",
    component: <AlertDemo />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <DashboardPage />,
  },
  {
    type: "page",
    name: "route.fdbs.history",
    key: "history",
  },
];

const ErrorRoute = [
  {
    type: "page",
    name: "route.fdbs.error",
    icon: <Heart size="12px" />,
    key: "default",
    route: "/dashboards/default",
    component: <Error500 />,
  },
];

const fdbsOperateurRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <Alert />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/tutorial",
    component: <AlertDemo />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <DashboardPage />,
  },
];

const fdbsDirectionRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <Alert />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/tutorial",
    component: <AlertDemo />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <DashboardPage />,
  },
];

const fdbsUpdtPwdRoute = [
  {
    type: "page",
    name: "route.fdbs.updt-pwd",
    icon: <Heart size="12px" />,
    key: "default",
    route: "/dashboards/default",
    component: <UpdtPwd />,
  },
];

const initRoutes = [
  {
    name: "default2",
    key: "default2",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "resetPassword",
    key: "resetPassword",
    route: "/authentication/reset-password",
    component: <ResetPwd />,
  },
  {
    name: "renewPassword",
    key: "renewPassword",
    route: "/authentication/renew-password",
    component: <RenewPwd />,
  },
  {
    name: "default",
    key: "default",
    route: "/dashboards/default",
    component: <Default />,
  },
];

const routesList = {
  0: initRoutes,
  1: adminRoutes,
  100001: fdbsUpdtPwdRoute,
  100002: ErrorRoute,
  5: fdbsOperateurRoutes,
  6: fdbsDirectionRoutes,
};

export default routesList;
