import { useState, useEffect } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import gbFlag from "fdbs/assets/flags/flag_gbr.png";
import frFlag from "fdbs/assets/flags/flag_fra.png";

import i18n from "i18n";

export default function DropdownCountries() {
  const [menu, setMenu] = useState(null);
  const [selectedFlag, setSelectedFlag] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  const populateFlag = (value) => {
    if (value === "fr") {
      setSelectedFlag(frFlag);
    }
    if (value === "en") {
      setSelectedFlag(gbFlag);
    }
  };

  useEffect(() => {
    const currentLng = getCurrentLng();
    const cleanLang = currentLng.substring(0, 2);
    populateFlag(cleanLang);
  });

  const changeLanguage = (selectedElmt) => {
    // console.log(selectedElmt.currentTarget.dataset.id);
    i18n.changeLanguage(selectedElmt.currentTarget.dataset.id);
    populateFlag(selectedElmt.currentTarget.dataset.id);
  };

  return (
    <>
      <SoftButton color="primary" fullWidth variant="outlined" onClick={openMenu}>
        <SoftBox component="img" src={selectedFlag} alt="" width="26px" opacity={0.9} />
        &nbsp;
        <Icon className="material-icons-round font-bold">keyboard_arrow_down</Icon>
      </SoftButton>
      <Menu
        anchorEl={menu}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(menu)}
        onClose={closeMenu}
        onClick={(menuItem) => changeLanguage(menuItem)}
      >
        <MenuItem data-id="fr" onClick={changeLanguage}>
          <SoftBox component="img" src={frFlag} alt="fr" width="15%" opacity={0.9} />
          <SoftTypography variant="h7">&nbsp;&nbsp;Francais</SoftTypography>
        </MenuItem>
        <MenuItem data-id="en" onClick={changeLanguage}>
          <SoftBox component="img" src={gbFlag} alt="en" width="15%" opacity={0.9} />
          <SoftTypography variant="h7">&nbsp;&nbsp;English</SoftTypography>
        </MenuItem>
      </Menu>
    </>
  );
}
