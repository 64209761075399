import { login } from "actions/userAction";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// react-router-dom components
import { Link } from "react-router-dom";

// formik components
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Translation components
import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";

import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";

// Authentication layout components
import IllustrationLayout from "fdbs/layouts/authentication/components/IllustrationLayout";

function Illustration() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const message = useSelector((state) => state.messageState);
  // const currentUser = useSelector((state) => state.userState);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  const validateSchema = Yup.object({
    email: Yup.string().email(t("error.invalid-email-address")).required(t("error.required")),
    password: Yup.string().required(t("error.required")),
  });

  const dispatchAction = (log, pass) => {
    dispatch(login(log, pass))
      .then(() => {
        console.log(" ---- result  ----");
        // console.log(result);
        // if (result.user.updatePwd === "1") {
        //   navigate("/toto/");
        // } else {
        //   navigate("/");
        // window.location.reload();
        // }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    dispatchAction(values.email, values.password);
  };

  return (
    <IllustrationLayout
      title={t("signin.title")}
      description={t("signin.description")}
      color="warning"
    >
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form autoComplete="off">
            {message.msgType !== "" ? (
              <SoftAlert color={message.msgType}>
                <Icon fontSize="small" className="text-gradient-error">
                  {message.msgType}
                </Icon>
                <SoftTypography variant="body2" color="white" sx={{ marginLeft: "5px" }}>
                  {t(message.msg)}
                </SoftTypography>
              </SoftAlert>
            ) : null}
            <SoftBox mb={2}>
              <Field
                name="email"
                as={SoftInput}
                placeholder={t("signin.email")}
                error={Boolean(errors.email)}
              />
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name="email" />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox mb={2}>
              <Field
                name="password"
                type="password"
                placeholder={t("signin.password")}
                as={SoftInput}
                error={Boolean(errors.password)}
              />
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name="password" />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                fullWidth
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="light" size={18} sx={{ marginRight: "5px" }} />
                ) : null}
                {t("signin.bt-signin")}
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {t("signin.forgot-your-password")}{" "}
                <SoftTypography
                  component={Link}
                  to="/authentication/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("signin.click-here")}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Form>
        )}
      </Formik>
    </IllustrationLayout>
  );
}

export default Illustration;
