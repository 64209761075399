import { useEffect, useState } from "react";

import { indicatorService } from "services";
import useErrorFormater from "hooks/useErrorFormater";

export default function useGetAlertsTypologies({ area = "" }) {
  const [addErrorMsg] = useErrorFormater();
  const [stats, setStats] = useState();

  useEffect(() => {
    setStats(null);
    indicatorService
      .getAlertsTypologies({ area })
      .then((result) => {
        setStats(result);
      })
      .catch((error) => addErrorMsg("getAlertsTypologies", error));
  }, [area]);

  return stats;
}
