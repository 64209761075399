import { apiErrorManager } from "helpers/apiErrorManager";
import { passwordService } from "../services";
import { passwordConstants, messageConstants } from "../constants";

function success(user) {
  return { type: passwordConstants.PWD_INIT_SUCCESS, payload: user };
}
function failure(error) {
  return { type: passwordConstants.PWD_INIT_ERROR, payload: error };
}

function message(msgType, msg) {
  const payload = { msgType, msg };
  return { type: messageConstants.SET_MESSAGE, payload };
}

export const forgotPassword = (email) => (dispatch) => {
  return passwordService.forgotPassword(email).then(
    (pwdResult) => {
      dispatch(message("", ""));
      dispatch(success(pwdResult));
      return Promise.resolve();
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      dispatch(failure(error));
      return Promise.reject();
    }
  );
};

export const renewPassword = (token, password, idUser) => (dispatch) => {
  return passwordService.renewPassword(token, password, idUser).then(
    (pwdResult) => {
      dispatch(message("", ""));
      dispatch(success(pwdResult));
      return Promise.resolve();
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      dispatch(failure(error));
      return Promise.reject();
    }
  );
};

export const verifyToken = (token, idUser) => (dispatch) => {
  return passwordService.verifyToken(token, idUser).then(
    (pwdResult) => {
      dispatch(message("", ""));
      dispatch(success(pwdResult));
      return Promise.resolve();
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      dispatch(failure(error));
      return Promise.reject();
    }
  );
};
