import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

import rootReducer from "reducers/rootReducer";

const loggerMiddleware = createLogger();

function configureStore(state) {
  return createStore(
    rootReducer,
    state,
    composeWithDevTools(applyMiddleware(reduxThunk, loggerMiddleware))
  );
}

export default configureStore;
