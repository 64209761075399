import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Menu } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import NotificationItem from "fdbs/Items/NotificationItem";

export default function NotificationButton() {
  const [openMenu, setOpenMenu] = useState(false);
  const currentNotification = useSelector((state) => state.notificationState);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
    // setNewNotification(false);
  };
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ marginTop: "1rem" }}
    >
      <NotificationItem
        color="warning"
        title={currentNotification.title}
        content={currentNotification.content}
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <>
      {currentNotification ? renderMenu() : null}
      <IconButton
        size="large"
        color="inherit"
        aria-controls="aide-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleOpenMenu}
        sx={{ marginRight: "20px" }}
      >
        <NotificationsOutlinedIcon />
      </IconButton>
    </>
  );
}
