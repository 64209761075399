import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Link } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import config from "config.js";

export default function GenericErrorDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const message = useSelector((state) => state.messageState);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message.msgType === "error") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  function onRefresh() {
    setOpen(false);
    navigate(0);
  }

  return (
    <Dialog open={open} fullWidth sx={{ "& .MuiPaper-root": { padding: "45px" } }}>
      <DialogTitle>{t("error.generic.dialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "24px" }}>
          {t("error.generic.dialog.content")}{" "}
          <Link rel="noopener noreferrer" target="_blank" href={config.FORM_REPORT}>
            {t("error.generic.dialog.contentEnd")}
          </Link>
          .
        </DialogContentText>
        <SoftBox sx={{ textAlign: "center" }}>
          <SoftButton
            variant="contained"
            color="primary"
            sx={{ "& svg": { fontSize: "1.5rem!important" } }}
            onClick={() => onRefresh()}
          >
            <AutorenewIcon sx={{ marginRight: "8px" }} /> {t("error.generic.dialog.button")}
          </SoftButton>
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}
