import API from "helpers/api";

const login = async (username, password) => {
  const path = "services/backOffice/signin/";

  const res = await API.post(path, { username, password });
  const dt = res.data.data;

  if (dt.user.token) {
    if (dt.user.expiresIn) {
      const currentTimeInMilliseconds = Date.now();
      const expireDateTime = currentTimeInMilliseconds + dt.user.expiresIn * 1000;
      dt.user.expireDateTime = expireDateTime;
    }
    localStorage.setItem("user", JSON.stringify(dt.user));
  }

  return dt;
};

const updtPwd = async (password) => {
  const path = `/services/backoffice/user/updtPwd`;
  const res = await API.post(path, {
    password,
  });
  const dt = res.data.data;

  if (dt.user.token) {
    if (dt.user.expiresIn) {
      const currentTimeInMilliseconds = Date.now();
      const expireDateTime = currentTimeInMilliseconds + dt.user.expiresIn * 1000;
      dt.user.expireDateTime = expireDateTime;
    }
    localStorage.setItem("user", JSON.stringify(dt.user));
  }

  return dt;
};

const logout = async () => {
  localStorage.removeItem("user");
};

export const userService = {
  login,
  updtPwd,
  logout,
};
