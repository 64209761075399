import { aedConstants } from "../constants";

const defaultData = {
  lon: 0,
  lat: 0,
  description: "",
};

export default (state = { data: defaultData }, action = {}) => {
  switch (action.type) {
    case aedConstants.INIT:
      return {};
    case aedConstants.UPDT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
