import { messageConstants } from "../constants";

const initialState = { msgType: "", msg: "" };

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case messageConstants.SET_MESSAGE:
      return { msgType: payload.msgType, msg: payload.msg };

    case messageConstants.CLEAR_MESSAGE:
      return { initialState };

    default:
      return state;
  }
};
