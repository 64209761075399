import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import configureStore from "store";
import setupInterceptors from "setupInterceptors";

import { I18nextProvider } from "react-i18next";
import i18n from "i18n";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { SoftUIControllerProvider } from "context";

import ReactGA from "react-ga4";
import config from "config";

import App from "App";

Bugsnag.start({
  apiKey: config.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
});

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(config.GA_ID_MEASURE);
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={configureStore()}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <SoftUIControllerProvider>
            <App />
          </SoftUIControllerProvider>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </ErrorBoundary>
);

setupInterceptors(configureStore());
