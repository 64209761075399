import { passwordConstants } from "../constants";

const initialState = { initPassword: false };

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case passwordConstants.PWD_INIT_REQUEST:
      return {
        ...state,
        initPassword: payload.user,
      };
    default:
      return state;
  }
};
