import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SoftBox from "components/SoftBox";

export default function AlertAutomaticCloseDialog({ open, onClose }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, 8000);
    }
  }, [open]);

  return (
    <Dialog open={open} sx={{ textAlign: "center" }}>
      <DialogTitle sx={{ padding: "35px 24px 16px 24px" }}>
        {t("alert.dialog.automaticClose.title")}
      </DialogTitle>
      <DialogContent sx={{ padding: "35px 24px" }}>
        <DialogContentText sx={{ marginBottom: "24px" }}>
          {t("alert.dialog.automaticClose.content")}
        </DialogContentText>
        <SoftBox sx={{ textAlign: "center" }}>
          <CircularProgress />
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}
