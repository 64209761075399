import { combineReducers } from "redux";
import userReducer from "reducers/userReducer";
import messageReducer from "reducers/messageReducer";
// import permissionReducer from "reducers/permissionReducer";
import accountReducer from "reducers/accountReducer";
import alertReducer from "reducers/alertReducer";
import aedReducer from "reducers/aedReducer";
import passwordReducer from "reducers/passwordReducer";
import notificationReducer from "reducers/notificationReducer";
import ongoingAlertsReducer from "reducers/ongoingAlertsReducer";

export default combineReducers({
  userState: userReducer,
  // permissionState: permissionReducer,
  messageState: messageReducer,
  accountState: accountReducer,
  aedState: aedReducer,
  alertState: alertReducer,
  ongoingAlertsState: ongoingAlertsReducer,
  passwordState: passwordReducer,
  notificationState: notificationReducer,
});
