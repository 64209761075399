import { useEffect, useState } from "react";
import { indicatorService } from "services";

import useErrorFormater from "hooks/useErrorFormater";

export default function useGetCitizensRespondersHeatmap(filter) {
  const [addErrorMsg] = useErrorFormater();
  const [stats, setStats] = useState();

  useEffect(() => {
    indicatorService
      .getCitizensRespondersHeatmap(filter)
      .then((result) => {
        setStats(result);
      })
      .catch((error) => addErrorMsg("getCitizensRespondersHeatmap", error));
  }, []);

  return stats;
}
