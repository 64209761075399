import { notificationConstants } from "../constants";

const notification = JSON.parse(localStorage.getItem("notification"));
let initialState = { title: "", content: "", link: "", new: false, version: 0 };
if (notification !== undefined && notification !== null) {
  initialState = {
    title: notification.title,
    content: notification.content,
    link: notification.link,
    version: notification.version,
  };
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case notificationConstants.SET_NOTIF: {
      let newFlag = false;
      if (payload.version > initialState.version) {
        newFlag = true;
      } else {
        newFlag = false;
      }
      return {
        title: payload.title,
        content: payload.content,
        link: payload.link,
        version: payload.version,
        new: newFlag,
      };
    }
    default:
      return state;
  }
};
