import { useTranslation } from "react-i18next";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { CircularProgress } from "@mui/material";

import ComparisonList from "../ComparisonList/ComparisonList";
import useGetAlertsRateRanking from "hooks/indicators/useGetAlertsRateRanking";

export default function ComparisonAlerts() {
  const { t } = useTranslation();
  const stats = useGetAlertsRateRanking();

  return (
    <SoftBox
      p={3}
      pb={2}
      sx={{
        backgroundColor: (theme) => theme.palette.white.main,
        borderRadius: "16px",
        boxShadow: "0px 2px 7px rgba(56, 71, 100, 0.3)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SoftTypography variant="h5">{t("dashboard.comparisons.alerts.title")}</SoftTypography>
      {stats && Object.entries(stats).length > 0 ? (
        <>
          <ComparisonList
            actualRank={{
              position: stats.position,
              label: stats.label,
              population: stats.population,
              value: stats.nbAlert,
              rate: stats.rate,
            }}
            podium={stats.ranking.map((rank) => ({
              position: rank.position,
              label: rank.label,
              population: rank.population,
              value: rank.nbAlert,
              rate: rank.rate,
            }))}
          />
          {stats.total ? (
            <SoftTypography
              sx={{ color: (theme) => theme.palette.grey[500], paddingTop: "0.5rem" }}
            >
              {t("dashboard.comparisons.alerts.total", { total: stats.total })}
            </SoftTypography>
          ) : null}
        </>
      ) : (
        <SoftBox sx={{ textAlign: "center" }} my={5}>
          <CircularProgress />
        </SoftBox>
      )}
    </SoftBox>
  );
}
