import { useTranslation } from "react-i18next";
import { Divider, Grid, List, ListItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

export default function ComparisonList({ actualRank, podium, type = "alerts" }) {
  const { t } = useTranslation();

  function formattedPopulation(population) {
    if (population / 1000000 > 1) {
      return parseFloat((population / 1000000).toFixed(0)) + "M";
    }
    return parseFloat((population / 1000).toFixed(0)) + "K";
  }

  function ListItemFormatted({ item }) {
    const { position, label, population, value, rate } = item;
    return (
      <>
        <ListItem>
          <Grid container>
            <Grid item xs={5}>
              <SoftTypography component="span" fontWeight="bold" sx={{ marginRight: "0.75rem" }}>
                {position}
              </SoftTypography>
              <SoftTypography component="span" fontWeight="bold">
                {label}
              </SoftTypography>
            </Grid>
            <Grid item xs={5}>
              <SoftTypography sx={{ color: (theme) => theme.palette.grey[500] }}>
                {t(`dashboard.comparisons.${type}.value`, {
                  [type]: value,
                  population: formattedPopulation(population),
                })}
              </SoftTypography>
            </Grid>
            <Grid item xs={2}>
              <SoftTypography fontWeight="bold" color="primary">
                {t(`dashboard.comparisons.${type}.comparable`, { rate })}
              </SoftTypography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider sx={{ marginTop: "0.75rem", marginBottom: "0.5rem" }} />
      </>
    );
  }

  return (
    <List sx={{ paddingTop: "1.5rem" }}>
      {podium.map((rank) => (
        <ListItemFormatted key={rank.label} item={rank} />
      ))}
      <ListItem>
        <SoftBox display="flex" justifyContent="center" width="100%" mb={2}>
          <MoreVertIcon fontSize="large" />
        </SoftBox>
      </ListItem>
      <ListItemFormatted item={actualRank} />
    </List>
  );
}
