import { useCallback } from "react";
import { useDispatch } from "react-redux";
import Bugsnag from "@bugsnag/js";

import { setMessage } from "actions/messageAction";

export default function useErrorFormater() {
  const dispatch = useDispatch();

  const addErrorMsg = useCallback((funct, objMsg) => {
    if (objMsg !== undefined) {
      const msgBundle = {
        msg: `${funct} - ${objMsg}`,
        msgType: "error",
      };

      dispatch(setMessage(msgBundle));
      Bugsnag.notify(new Error(msgBundle.msg));
    }
  }, []);

  return [addErrorMsg];
}
