const config = {
  API_URL: "https://aedmap.io/",
  MEDIA_URL: "https://admin.aedmap.org/media/upload/",
  BACKEND_TYPE: "CITIZEN-RESPONDER",
  BACKEND_ID: 259,
  BUGSNAG_KEY: "86e40c0727e1c499355ade51c421edf4",
  EMAIL_CONTACT_US: "contact@bon-samaritain.org",
  EMAIL_REPORT: "contact@bon-samaritain.org",
  GMAP_KEY: "AIzaSyCLRdKbTQ7uVOV4KfW2G46RCbEo4mUmp3c",
  API_VIDEO_KEY: "46271532",
  MAX_DISTANCE_ADDRESSES: 0.3, // Valeur en kilomètre
  PROTOCOL: "https",
  GA_ID_MEASURE: "G-QHNDC5T184",
  WEBSOCKET_ENV: "PROD",
  WEBSOCKET_ALERT_URL: "wss://tn4ndbzuhk.execute-api.eu-west-1.amazonaws.com/production",
  WEBSOCKET_SITE_URL: "wss://bqkhzyly5l.execute-api.eu-west-1.amazonaws.com/production",
  FORM_REPORT: "https://airtable.com/shrcqqiFeMiAYxXje",
};

module.exports = config;
