import { useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import config from "config.js";
import { getAlert, onCall } from "actions/alertAction";
import { setMessage } from "actions/messageAction";
import { unloadAlert } from "actions/alertAction";

/**
 * Hook reducer personnalisé permettant de controler une connexion au WebSocket ALERTE.
 * Le Websocket prévient s'il faut :
 * - Mettre à jour l'alerte
 * - Arrêter de suivre l'alerte
 */
export default function useAlertWebSocket() {
  const dispatch = useDispatch();
  const currentUser = useSelector((store) => store.userState);
  const currentAlert = useSelector((store) => store.alertState.alert);

  const ws = useRef(null);

  function refreshAlert() {
    dispatch(getAlert(currentAlert.alertId)).catch((error) =>
      dispatch(setMessage({ msg: `useAlertWebSocket - getAlert - ${error}`, msgType: "error" }))
    );
  }

  function refreshCitizenResponders() {
    dispatch(onCall(currentAlert.alertId)).catch((error) =>
      dispatch(
        setMessage({
          msg: `useAlertWebSocket - refreshCitizenResponders - ${error}`,
          msgType: "error",
        })
      )
    );
  }

  function reducer(state, action) {
    function openConnection() {
      if (currentAlert?.alertId) {
        ws.current = new W3CWebSocket(config.WEBSOCKET_ALERT_URL);
        ws.current.onopen = () => {
          console.log("OUVERTURE DU WEBSOCKET");
          ws.current.send(
            JSON.stringify({
              action: "setName",
              name: `${currentUser.user.accountName}_${currentAlert.alertId}_${Math.floor(
                Math.random() * 10000
              )}`,
              keyAlert: currentAlert.alertId.toString(),
              env: config.WEBSOCKET_ENV,
            })
          );
        };
      }
    }

    function monitoringNewMessages() {
      if (ws.current) {
        ws.current.onmessage = (event) => {
          console.log(ws.current);
          const message = JSON.parse(event.data).eventMsg;
          console.log("MESSAGE WEBSOCKET RECU :");
          console.log(event);
          switch (message) {
            case "REFRESH_BS":
              refreshCitizenResponders();
              break;
            case "REFRESH_ALERT":
              refreshAlert();
              break;
            case "CLOSE_ALERT":
              refreshAlert();
              break;
            default:
              break;
          }
        };
      }
    }

    function sendMessage(message, alertId) {
      if (ws.current.readyState) {
        console.log("J4ENVOI UN MESSAGE ", message, alertId);
        ws.current.send(
          JSON.stringify({
            action: message,
            name: `${currentUser.user.accountName}_${alertId}`,
            keyAlert: alertId.toString(),
            env: config.WEBSOCKET_ENV,
          })
        );
      }
    }

    function closeConnection() {
      if (ws.current?.readyState === 1) {
        ws.current.close();
      }
    }

    switch (action.type) {
      case "CONNEXION":
        console.log("CONNEXION WEBSOCKET ALERT");
        openConnection();
        monitoringNewMessages();
        break;
      case "CLOSE":
        console.log("CLOSE WEBSOCKET ALERT");
        closeConnection();
        break;
      case "SEND_MESSAGE_REFRESH_ALERT":
        sendMessage("alertEvent", action.alertId);
        break;
      case "SEND_MESSAGE_CLOSE_ALERT":
        sendMessage("alertClose", action.alertId);
        break;
      default:
        throw new Error(`L'action ${action.type} n'est pas reconnu`);
    }
    return ws.current?.readyState === 1;
  }
  return useReducer(reducer, false);
}
