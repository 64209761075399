import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
// import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// custom styles for the NotificationItem
import { menuItem } from "examples/Items/NotificationItem/styles";

const NotificationItem = forwardRef(({ color, title, content, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <SoftBox>
      <SoftTypography variant="button" fontWeight="bold">
        {title}
      </SoftTypography>
      <br />
      <br />
      <div
        id="contentNotif"
        sx={{ whiteSpace: "normal" }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </SoftBox>
  </MenuItem>
));

NotificationItem.displayName = "NotificationItem";

// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default NotificationItem;
