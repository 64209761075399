import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

export default function UserCard() {
  const { t } = useTranslation();
  const currentUser = useSelector((store) => store.userState.user);
  return (
    <SoftBox display="flex">
      {!currentUser.accountLogo ? null : (
        <SoftBox
          component="img"
          src={currentUser.accountLogo}
          alt={`Logo ${currentUser.accountName}`}
          sx={{ width: "40px" }}
        />
      )}
      <SoftBox
        px={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minWidth: "0",
        }}
      >
        <SoftTypography
          variant="body1"
          pb={1}
          sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {currentUser.accountName}
        </SoftTypography>
        <SoftTypography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "grey.500",
          }}
        >
          {t(`user.group.${currentUser.idGroup}`)}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}
