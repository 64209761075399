import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, ListItemText, Menu, MenuItem, SvgIcon } from "@mui/material";
import config from "config.js";
import { Link } from "react-router-dom";

export default function HelpButton() {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };
  const handleCloseMenu = () => setOpenMenu(false);

  const displayMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ marginTop: "1rem" }}
    >
      <MenuItem component="a" rel="noopener noreferrer" target="_blank" href={config.FORM_REPORT}>
        <ListItemText>{t("header.helpButton.menuItem.report")}</ListItemText>
      </MenuItem>
      <MenuItem component="a" rel="noopener noreferrer" href={`mailto:${config.EMAIL_CONTACT_US}`}>
        <ListItemText>{t("header.helpButton.menuItem.contact")}</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={`/tutorial`}>
        <ListItemText>{t("header.helpButton.menuItem.tutorial")}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {openMenu ? displayMenu() : null}
      <IconButton
        size="large"
        color="inherit"
        aria-controls="aide-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleOpenMenu}
        sx={{ marginRight: "10px" }}
      >
        <SvgIcon viewBox="0 0 32 32">
          <path
            d="M14.6667 24H17.3334V21.3333H14.6667V24ZM16 2.66663C14.2491 2.66663 12.5152 3.0115 10.8976 3.68157C9.2799 4.35163 7.81004 5.33375 6.57193 6.57187C4.07144 9.07235 2.66669 12.4637 2.66669 16C2.66669 19.5362 4.07144 22.9276 6.57193 25.428C7.81004 26.6662 9.2799 27.6483 10.8976 28.3184C12.5152 28.9884 14.2491 29.3333 16 29.3333C19.5362 29.3333 22.9276 27.9285 25.4281 25.428C27.9286 22.9276 29.3334 19.5362 29.3334 16C29.3334 14.249 28.9885 12.5152 28.3184 10.8975C27.6484 9.27984 26.6662 7.80998 25.4281 6.57187C24.19 5.33375 22.7201 4.35163 21.1025 3.68157C19.4848 3.0115 17.751 2.66663 16 2.66663ZM16 26.6666C10.12 26.6666 5.33335 21.88 5.33335 16C5.33335 10.12 10.12 5.33329 16 5.33329C21.88 5.33329 26.6667 10.12 26.6667 16C26.6667 21.88 21.88 26.6666 16 26.6666ZM16 7.99996C14.5855 7.99996 13.229 8.56186 12.2288 9.56206C11.2286 10.5622 10.6667 11.9188 10.6667 13.3333H13.3334C13.3334 12.626 13.6143 11.9478 14.1144 11.4477C14.6145 10.9476 15.2928 10.6666 16 10.6666C16.7073 10.6666 17.3855 10.9476 17.8856 11.4477C18.3857 11.9478 18.6667 12.626 18.6667 13.3333C18.6667 16 14.6667 15.6666 14.6667 20H17.3334C17.3334 17 21.3334 16.6666 21.3334 13.3333C21.3334 11.9188 20.7714 10.5622 19.7713 9.56206C18.7711 8.56186 17.4145 7.99996 16 7.99996Z"
            fill="#384764"
          />
        </SvgIcon>
      </IconButton>
    </>
  );
}
