import { renewPassword, verifyToken } from "actions/passwordAction";

import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import { Link, useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// Translation components
import { useTranslation } from "react-i18next";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftAlert from "components/SoftAlert";

import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Authentication layout components
import IllustrationLayout from "fdbs/layouts/authentication/components/IllustrationLayout";

function RenewPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.messageState);

  const [loading, setLoading] = useState(false);

  // == Get params
  const getResult = new URLSearchParams(window.location.search);
  const tokenUser = getResult.get("token");
  const idUser = getResult.get("id");
  //  ===

  useEffect(() => {
    dispatch(verifyToken(tokenUser, idUser));
  }, []);

  const validateSchema = Yup.object({
    pwd: Yup.string()
      .required(t("error.required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, // "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        t("error.password-must-strong")
      ),
    pwdConfirm: Yup.string().oneOf([Yup.ref("pwd"), null], t("error.passwords-must-match")),
  });

  function showSuccessPopin() {
    Swal.fire({
      title: t("renewPwd.title-validation"),
      text: t("renewPwd.text-validation"),
      icon: "success",
    }).then(() => {
      navigate("/authentication/signin");
    });
  }

  const dispatchAction = (token, pwd, id) => {
    dispatch(renewPassword(token, pwd, id))
      .then(() => {
        setLoading(false);
        showSuccessPopin();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    dispatchAction(tokenUser, values.pwd, idUser);
  };

  return (
    <IllustrationLayout
      title={t("renewPwd.title")}
      description={t("renewPwd.description")}
      color="warning"
    >
      <Formik
        initialValues={{ pwd: "", pwdConfirm: "" }}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form autoComplete="off">
            {message.msgType !== "" ? (
              <SoftAlert color={message.msgType}>
                <Icon fontSize="small" className="text-gradient-error">
                  {message.msgType}
                </Icon>
                <SoftTypography variant="body2" color="white" sx={{ marginLeft: "5px" }}>
                  {t(message.msg)}
                </SoftTypography>
              </SoftAlert>
            ) : null}
            <SoftBox mb={2}>
              <Field
                name="pwd"
                type="password"
                as={SoftInput}
                placeholder={t("renewPwd.pwd")}
                error={Boolean(errors.pwd)}
              />
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name="pwd" />
                </SoftTypography>
              </SoftBox>{" "}
            </SoftBox>
            <SoftBox mb={2}>
              <Field
                name="pwdConfirm"
                type="password"
                as={SoftInput}
                placeholder={t("renewPwd.pwd-confirm")}
                error={Boolean(errors.pwdConfirm)}
              />
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name="pwdConfirm" />
                </SoftTypography>
              </SoftBox>{" "}
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                fullWidth
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="light" size={18} sx={{ marginRight: "5px" }} />
                ) : null}
                {t("renewPwd.bt-validate")}
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {t("renewPwd.back-to-signin")}{" "}
                <SoftTypography
                  component={Link}
                  to="/authentication/signin"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("renewPwd.click-here")}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Form>
        )}
      </Formik>
    </IllustrationLayout>
  );
}

export default RenewPassword;
