// react-router-dom components
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";

// Translation components
import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "fdbs/components/Navbars/DefaultNavbar";
import PageLayout from "fdbs/layouts/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Images
import pattern from "fdbs/assets/images/background/error.gif";

function Error500() {
  const { t } = useTranslation();
  const currentMessage = useSelector((state) => state.messageState);

  const { d1, d3, d4, d5 } = typography;
  const emptyRoute = [];

  return (
    <PageLayout white>
      <DefaultNavbar routes={emptyRoute} transparent />
      <SoftBox my={18} height="calc(100vh - 18rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox
                fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                lineHeight={1.2}
              >
                <SoftTypography variant="inherit" color="warning" textGradient fontWeight="bold">
                  {t("errorPage.title")}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="text" fontWeight="bold">
                {t("errorPage.chapo")}
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text" opacity={0.6}>
                  {currentMessage.msg ? (
                    <SoftTypography variant="body1" color="error">
                      {currentMessage.msg}
                    </SoftTypography>
                  ) : null}
                  {t("errorPage.description")}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox component="img" src={pattern} alt="error-404" width="50%" />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

export default Error500;
