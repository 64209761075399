import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import SoftBox from "components/SoftBox";

import { alertAedSelected, wrongAddress } from "actions/alertAction";
import AddressTooFarDialog from "fdbs/layouts/dashboards/alert/dialogs/AddressTooFarDialog/AddressTooFarDialog";
import UnclearAddressDialog from "fdbs/layouts/dashboards/alert/dialogs/UnclearAddressDialog/UnclearAddressDialog";
import UpdateAddressDialog from "fdbs/layouts/dashboards/alert/dialogs/UpdateAddressDialog/UpdateAddressDialog";
import AedCard from "./AedCard";
import config from "config";
import { getDistance } from "fdbs/layouts/dashboards/alert/tools";
import greyMapStyle from "fdbs/layouts/dashboards/alert/Map/greyMapStyles";
import iconAED from "fdbs/assets/icons/aed.png";
import iconAEDWithPhone from "fdbs/assets/icons/aedWithPhone.png";
import iconAEDHovered from "fdbs/assets/icons/aed_hovered.png";
import iconAEDWithPhoneHovered from "fdbs/assets/icons/aedWithPhone_hovered.png";
import iconAEDSelected from "fdbs/assets/icons/aed_selected.png";
import iconAEDWithPhoneSelected from "fdbs/assets/icons/aedWithPhone_selected.png";
import iconVictim from "fdbs/assets/icons/iconVictim.png";
import iconVictimHovered from "fdbs/assets/icons/iconVictimHovered.png";
import iconVictimSelected from "fdbs/assets/icons/iconVictimSelected.png";
import pinMapBlue from "fdbs/assets/icons/pin_map_blue.svg";
import pinMapGreen from "fdbs/assets/icons/pin_map_green.svg";
import pinMapGrey from "fdbs/assets/icons/pin_map_grey.svg";
import pinMapOrange from "fdbs/assets/icons/pin_map_orange.svg";
import pinMapRed from "fdbs/assets/icons/pin_map_red.svg";
import satelliteMapStyles from "./satelliteMapStyles";
import simplifiedMapStyles from "./simplifiedMapStyles";

export default function Map({ onAddressChange, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentAedSelected = useSelector((store) => store.alertState.aedSelected);
  const currentAedsList = useSelector((store) => store.alertState.aed);
  const currentAlert = useSelector((store) => store.alertState.alert);
  const currentAlertOptions = useSelector((store) => store.alertState.options);
  const currentCitizenResponders = useSelector((store) => store.alertState.citizenResponder);
  const currentSelectCountry = useSelector((store) => store.alertState.countrySelected);

  const [aedPopin, setAedPopin] = useState(null);
  const [googleMapsApi, setGoogleMapsApi] = useState(null);
  const [newDragendPosition, setNewDragendPosition] = useState(null);
  const [newPlace, setNewPlace] = useState(null);
  const [openDialogAddressTooFar, setOpenDialogAddressTooFar] = useState(false);
  const [openDialogUnclearAddress, setOpenDialogUnclearAddress] = useState(false);
  const [potentialNewAddress, setPotentialNewAddress] = useState();

  const circleAlert = useRef(null);
  const flightPathsRef = useRef([]);
  const mapRef = useRef(null);
  const markerIncidentRef = useRef(null);
  const markersAedsRef = useRef([]);
  const markersCitizenRespondersRef = useRef([]);
  let autocomplete;

  /** Positionnement de la carte en fonction des options du service de secours rattaché */
  useEffect(() => {
    if (googleMapsApi && !currentAlert.lon && !currentAlert.lat) {
      mapRef.current.setCenter(new googleMapsApi.LatLng(currentAlertOptions.config.positionInit));
      mapRef.current.setZoom(currentAlertOptions.config.zoomInit);
    }
  }, [googleMapsApi, currentAlertOptions.config.positionInit, currentAlert.lon, currentAlert.lat]);

  /** Mise en place de l'autocompletion d'adresse sur le champ formulaire s'il existe */
  useEffect(() => {
    if (googleMapsApi && document.getElementById("form-address")) {
      autocomplete = new googleMapsApi.places.Autocomplete(
        document.getElementById("form-address"),
        {
          componentRestrictions: { country: currentSelectCountry.toLowerCase() },
        }
      );

      autocomplete.bindTo("bounds", mapRef.current);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.types) {
          setNewPlace(place);
        } else {
          const addressWrited = place.name;
          const geocoder = new googleMapsApi.Geocoder();
          geocoder.geocode({ address: addressWrited }, (results, status) => {
            if (status === googleMapsApi.GeocoderStatus.OK) {
              setNewPlace(results[0]);
            } else {
              dispatch(wrongAddress());
            }
          });
        }
      });
    } else {
      console.log(" form-address  dans les choux ");
    }
  }, [googleMapsApi, document.getElementById("form-address"), currentAlert]);

  /** Lorsqu'un changement a lieu dans la liste des défibrillateurs */
  useEffect(() => {
    if (googleMapsApi) {
      if (markersAedsRef.current) {
        markersAedsRef.current.forEach((marker) => marker.setMap(null));
      }
      if (currentAedsList?.length > 0) {
        setAedMarkers(currentAedsList);
      }
    }
  }, [googleMapsApi, currentAedsList]);

  /** Lorsqu'un changement d'adresse a lieu */
  useEffect(() => {
    if (googleMapsApi) {
      if (currentAlert.lon && currentAlert.lat) {
        const latLng = new googleMapsApi.LatLng(currentAlert.lat, currentAlert.lon);
        setAlertMarker(latLng);
      } else {
        setAlertMarker();
      }
    }
  }, [googleMapsApi, currentAlert.lon, currentAlert.lat]);

  /** Gestion du cercle lorsque l'alerte est déclenchée ou l'adresse modifiée en cours d'intervention */
  useEffect(() => {
    if (googleMapsApi) {
      if (currentAlert.status === 2) {
        const circleRadius = currentAlert.distanceMax * 1000;
        showCircle("init", circleRadius, currentAlert.nbRequests);
      } else if (circleAlert.current) {
        circleAlert.current.setMap(null);
      }
    }
  }, [googleMapsApi, currentAlert.status, currentAlert.address]);

  /** Lorsqu'il y a une mise à jour sur la liste des Bons Samaritains de l'alerte en cours */
  useEffect(() => {
    if (googleMapsApi) {
      if (currentCitizenResponders && currentCitizenResponders.length) {
        currentCitizenResponders.forEach((citizenResponder) => {
          let newLocation = true;
          const titleMarker = `user-${citizenResponder.citizenResponderId}`;
          const coords = new googleMapsApi.LatLng(
            citizenResponder.alertInfo.lat,
            citizenResponder.alertInfo.lon
          );

          let urlNameIcon = "";
          let fontColor = "#344767";

          switch (citizenResponder.alertInfo.status) {
            case "UNAVAILABLE":
            case "STOPPED":
            case "CANCELLED":
            case "WAITING_TRAINED":
            case "WITHOUT_AED_CANCEL":
              urlNameIcon = pinMapGrey;
              fontColor = "#868C9A";
              break;
            case "AVAILABLE":
            case "DISPATCHED":
              urlNameIcon = pinMapBlue;
              fontColor = "#50ADBC";
              break;
            case "ON_THE_WAY_AED":
            case "ON_SITE_AED":
              urlNameIcon = pinMapGreen;
              fontColor = "#5DB27E";
              break;
            case "ON_THE_WAY_VICTIM":
              urlNameIcon = pinMapOrange;
              fontColor = "#EDBF4B";
              break;
            case "ON_SITE_VICTIM":
              urlNameIcon = pinMapRed;
              fontColor = "#C3291C";
              break;
            default:
              urlNameIcon = pinMapGrey;
              fontColor = "#868C9A";
          }

          const markerLabel = {
            text: citizenResponder.number.toString(),
            fontFamily: "Roboto",
            fontWeight: "700",
            color: fontColor,
            fontSize: "14px",
          };

          markersCitizenRespondersRef.current.forEach((marker, index) => {
            if (marker.title === titleMarker) {
              marker.setPosition(coords);
              marker.setIcon(iconFormatter(urlNameIcon, 28, 42, 14, 14));
              marker.setLabel(markerLabel);

              const path = flightPathsRef.current[index].getPath();
              path.push(coords);
              newLocation = false;
            }
          });

          if (newLocation) {
            const marker = new googleMapsApi.Marker({
              draggable: false,
              position: coords,
              label: markerLabel,
              map: mapRef.current,
              title: `user-${citizenResponder.citizenResponderId}`,
              icon: iconFormatter(urlNameIcon, 28, 42, 14, 14),
            });

            const lineSymbol = {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 2,
            };

            const flightPath = new googleMapsApi.Polyline({
              strokeColor: "#ce1d2e",
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "10px",
                },
              ],
            });

            flightPath.setMap(mapRef.current);

            flightPathsRef.current.push(flightPath);
            markersCitizenRespondersRef.current.push(marker);
          }
        });
      } else {
        markersCitizenRespondersRef.current.forEach((marker) => marker.setMap(null));
        flightPathsRef.current.forEach((flightPath) => flightPath.setMap(null));
      }
    }
  }, [googleMapsApi, currentCitizenResponders]);

  /** Lorsqu'un défibrillateur est dé/sélectionné */
  useEffect(() => {
    unselectAedIcons();
    if (currentAedSelected !== 0) {
      const index = currentAedsList.findIndex((e) => e.id === currentAedSelected);
      let withPhone = "";
      if (currentAedsList[index] !== undefined) {
        withPhone = currentAedsList[index].phoneNumber;
      }
      googleMapsApi.event.clearListeners(markersAedsRef.current[index], "mouseover");
      googleMapsApi.event.clearListeners(markersAedsRef.current[index], "mouseout");
      markersAedsRef.current[index].setIcon(
        withPhone ? iconFormatter(iconAEDWithPhoneSelected) : iconFormatter(iconAEDSelected)
      );

      setAedPopin(currentAedsList[index]);
    } else {
      setAedPopin(null);
    }
  }, [currentAedSelected]);

  /** Lorsqu'une nouvelle adresse est saisie
   * Comportement de passer par ce useEffect car les évènements créés (addListener) gardent les états initiaux de Redux.
   * Exemple : currentAlert sera toujours à l'état initial au sein de la fonction du listener, ce qui pose problème.
   * Solution : Pour la mise à jour d'adresse, les listeners mettront à jour une state intermédiaire qui sera vu par ce useEffect.
   */
  useEffect(() => {
    if (newPlace) {
      if (
        checkDistanceWithActualAddress(
          newPlace.geometry.location.lng(),
          newPlace.geometry.location.lat()
        )
      ) {
        handleAddress(newPlace);
      } else {
        setOpenDialogAddressTooFar(true);
      }
    }
  }, [newPlace]);

  /** Lorsque le pin est repositionné
   * Comportement de passer par ce useEffect car les évènements créés (addListener) gardent les états initiaux de Redux.
   * Exemple : currentAlert sera toujours à l'état initial au sein de la fonction du listener, ce qui pose problème.
   * Solution : Pour la mise à jour d'adresse, les listeners mettront à jour une state intermédiaire qui sera vu par ce useEffect.
   */
  useEffect(() => {
    if (newDragendPosition) {
      if (checkDistanceWithActualAddress(newDragendPosition.lon, newDragendPosition.lat)) {
        /* showAlertMovePopup(newDragendPosition); */
        setPotentialNewAddress({
          address: newDragendPosition.formatted_address,
          lat: newDragendPosition.lat,
          lon: newDragendPosition.lon,
        });
      } else {
        setOpenDialogAddressTooFar(true);
      }
    }
  }, [newDragendPosition]);

  /** Lorsque l'api et la map sont en place
   * Attribution de l'action si l'utilisateur clique sur la carte
   */
  useEffect(() => {
    if (googleMapsApi && mapRef.current) {
      mapRef.current.addListener("click", () => {
        dispatch(alertAedSelected(0));
        if (markersAedsRef.current) {
          markersAedsRef.current.forEach((marker) => marker.setIcon(iconFormatter(iconAED)));
        }
      });
    }
  }, [googleMapsApi, mapRef]);

  /**
   * Récupère les références de la carte et de l'API pour être utilisé par d'autres fonctions
   * @param {*} map - La carte
   * @param {*} api - L'API Google Maps
   */
  function apiHasLoaded(map, api) {
    const renamedSimplifiedMap = new api.StyledMapType(simplifiedMapStyles, {
      name: t("alert.map.style.simplified"),
    });
    const styledMap = new api.StyledMapType(greyMapStyle, {
      name: t("alert.map.style.detailed"),
    });
    const renamedSatelliteMap = new api.StyledMapType(satelliteMapStyles, {
      name: t("alert.map.style.satellite"),
    });

    map.mapTypes.set("simplified", renamedSimplifiedMap);
    map.mapTypes.set("greyMapStyle", styledMap);
    map.mapTypes.set("satelliteRenamed", renamedSatelliteMap);
    map.setMapTypeId("simplified");
    map.setOptions({
      zoomControl: true,
      mapTypeControl: true,
      streetViewControl: false,
      rotateControl: false,
      scaleControl: false,
      fullscreenControl: false,
      mapTypeControlOptions: {
        style: api.MapTypeControlStyle.DEFAULT,
        position: api.ControlPosition.TOP_RIGHT,
        mapTypeIds: [
          /* api.MapTypeId.ROADMAP */ "simplified",
          "greyMapStyle",
          api.MapTypeId.HYBRID /* "satelliteRenamed" */,
        ],
      },
    });

    mapRef.current = map;
    setGoogleMapsApi(api);
  }

  /**
   * Vérifie si la distance entre l'adresse d'origine et la nouvelle ont une distance raisonnable.
   * @param {*} newLon Longitude de la nouvelle adresse à contrôler.
   * @param {*} newLat Latitude de la nouvelle adresse à contrôler.
   * @returns {boolean} Renvoie true si la distance entre les 2 adresses est inférieure à la distance maximale paramétrée
   *                    OU si l'alerte n'est pas déclenchée
   *                    OU s'il n'y a pas d'adresse d'origine.
   */
  function checkDistanceWithActualAddress(newLon, newLat) {
    let distanceOK = true;
    if (currentAlert.address && currentAlert.status === 2) {
      const distance = getDistance(newLon, newLat, currentAlert.lon, currentAlert.lat);
      distanceOK = distance > config.MAX_DISTANCE_ADDRESSES ? false : true;
    }
    return distanceOK;
  }

  /**
   * Gestion de la réponse de la Modal du changement d'adresse trop lointaine de celle d'origine.
   * @param {boolean} answer Réponse de l'utilisateur ; undefined : Annuler ; true : Terminer l'intervention
   */
  function dialogAddressTooFarAnswerHandler(answer) {
    setOpenDialogAddressTooFar(false);
    const latLng = new googleMapsApi.LatLng(currentAlert.lat, currentAlert.lon);
    setAlertMarker(latLng);
    if (document.getElementById("form-address")) {
      document.getElementById("form-address").value = currentAlert.address;
    }
    if (answer) {
      onClose();
    }
  }

  /**
   * Gestion de la réponse de la Modal de mise à jour d'adresse.
   * @param {boolean} answer Réponse de l'utilisateur ; undefined : Annuler ; address : Nouvelle adresse
   */
  function dialogUpdateAddressAnswerHandler(answer) {
    if (answer) {
      const newAddressPosition = potentialNewAddress;
      newAddressPosition.address = answer;
      onAddressChange(newAddressPosition);
    } else {
      const initialPosition = new googleMapsApi.LatLng(currentAlert.lat, currentAlert.lon);
      markerIncidentRef.current.setPosition(initialPosition);
    }
    setPotentialNewAddress();
  }

  /**
   * Vérifie la place (format Google Maps) et la remonte au composant parent pour mise à jour de l'alerte si besoin
   * @param {google.maps.places.PlaceResult} myPlace - Place au format Google Maps
   */
  function handleAddress(myPlace) {
    if (myPlace.types[0] !== "undefined") {
      if (
        myPlace.types[0] === "locality" ||
        myPlace.types[0] === "route" ||
        myPlace.types[0] === "sublocality_level_1" ||
        myPlace.types[0] === "sublocality_level_2"
      ) {
        setOpenDialogUnclearAddress(true);
      }
    }

    if (myPlace.geometry) {
      const address = {
        lat: myPlace.geometry.location.lat(),
        lon: myPlace.geometry.location.lng(),
        address: myPlace.formatted_address,
      };

      setAlertMarker(myPlace.geometry.location);
      onAddressChange(address);
    } else {
      console.log(" Place not Found ");
    }
  }

  /**
   * Prépare l'url d'une icône au format désiré par un repère Google Maps
   * @param {*} urlNameIcon - URL de l'icône
   * @returns Icône formatée
   */
  function iconFormatter(urlNameIcon, width = 38, height = 60, xLabel = 0, yLabel = 0) {
    const myIcon = {
      url: urlNameIcon,
      size: new googleMapsApi.Size(width, height),
      scaledSize: new googleMapsApi.Size(width, height),
      labelOrigin: new googleMapsApi.Point(xLabel, yLabel),
    };
    return myIcon;
  }

  /**
   * Place les repères représentant les défibrillateurs autour de l'alerte.
   * @param {*} aedsList - Liste des défibrillateurs à afficher
   */
  function setAedMarkers(aedsList) {
    const markers = [];
    aedsList.forEach((aed) => {
      let aedPhone = "";
      if (aed.phoneNumber && aed.phoneNumber !== undefined && aed.phoneNumber !== null) {
        aedPhone = ` - ${aed.phoneNumber}`;
      }
      const coords = new googleMapsApi.LatLng(aed.lat, aed.lon);
      const marker = new googleMapsApi.Marker({
        draggable: false,
        position: coords,
        map: mapRef.current,
        title: `${aed.name}${aedPhone}`,
        icon: aedPhone ? iconFormatter(iconAEDWithPhone) : iconFormatter(iconAED),
      });

      googleMapsApi.event.addListener(marker, "click", () => {
        dispatch(alertAedSelected(aed.id));
      });

      googleMapsApi.event.addListener(marker, "mouseover", () => {
        marker.setIcon(
          aedPhone ? iconFormatter(iconAEDWithPhoneHovered) : iconFormatter(iconAEDHovered)
        );
      });

      googleMapsApi.event.addListener(marker, "mouseout", () => {
        marker.setIcon(aedPhone ? iconFormatter(iconAEDWithPhone) : iconFormatter(iconAED));
      });

      markers.push(marker);
    });
    markersAedsRef.current = markers;
  }

  /**
   * Place un repère à l'emplacement de l'alerte. Représente la victime.
   * @param {google.maps.LatLng} position - Coordonnées géographiques au format Google Maps
   */
  function setAlertMarker(position) {
    if (!markerIncidentRef.current) {
      markerIncidentRef.current = new googleMapsApi.Marker({
        map: mapRef.current,
        draggable: true,
        anchorPoint: new googleMapsApi.Point(0, -29),
        zIndex: 999,
      });

      markerIncidentRef.current.addListener("mouseover", () => {
        markerIncidentRef.current.setIcon(iconFormatter(iconVictimHovered));
      });
      markerIncidentRef.current.addListener("mouseout", () => {
        markerIncidentRef.current.setIcon(iconFormatter(iconVictim));
      });
      markerIncidentRef.current.addListener("dragstart", () => {
        markerIncidentRef.current.setIcon(iconFormatter(iconVictimSelected));
      });
      markerIncidentRef.current.addListener("dragend", () => {
        markerIncidentRef.current.setIcon(iconFormatter(iconVictimHovered));
        const geocoder = new googleMapsApi.Geocoder();
        geocoder.geocode(
          {
            latLng: markerIncidentRef.current.getPosition(),
          },
          (responses) => {
            if (responses && responses.length > 0) {
              setNewDragendPosition({
                formatted_address: responses[0].formatted_address,
                lat: markerIncidentRef.current.position.lat(),
                lon: markerIncidentRef.current.position.lng(),
              });
            } else {
              setNewDragendPosition();
            }
          }
        );
      });
    }

    if (position) {
      markerIncidentRef.current.setIcon(iconFormatter(iconVictim));

      markerIncidentRef.current.setPosition(position);
      markerIncidentRef.current.setVisible(true);
      markerIncidentRef.current.setAnimation(googleMapsApi.Animation.BOUNCE);
      setTimeout(() => markerIncidentRef.current.setAnimation(null), 1500);

      mapRef.current.setCenter(position);
      mapRef.current.setZoom(16);
    } else {
      markerIncidentRef.current.setVisible(false);
    }
  }

  /**
   * Affiche le cercle de recherche de Bons Samaritains
   * @param {*} type - La méthode d'affichage. "init" : avec animation ; Sinon sans animation
   * @param {*} radiusDef - Rayon du cercle
   * @param {*} nbResponder - Nombre de Bons Samaritains ayant répondu
   */
  function showCircle(type, radiusDef, nbResponder) {
    console.log("==> showCircle ");
    if (circleAlert.current) circleAlert.current.setMap(null);
    let timeStamp = 0;
    let fullRadius = false;

    const circleOption = {
      strokeColor: "#ff6100",
      strokeOpacity: 0.7,
      strokeWeight: 0,
      fillColor: "#ff6100",
      fillOpacity: 0.1,
      map: mapRef.current,
      center: new googleMapsApi.LatLng(currentAlert.lat, currentAlert.lon),
      radius: 10,
    };

    if (radiusDef > 600) {
      mapRef.current.setZoom(15);
    }
    if (radiusDef > 1200) {
      mapRef.current.setZoom(14);
    }
    if (radiusDef > 3000) {
      mapRef.current.setZoom(13);
    }

    circleAlert.current = new googleMapsApi.Circle(circleOption);

    circleAlert.current.addListener("click", () => {
      dispatch(alertAedSelected(0));
      unselectAedIcons();
    });

    if (type === "init") {
      function easeInOutSine(ti, b, c, d) {
        return (-c / 2) * (Math.cos((Math.PI * ti) / d) - 1) + b;
      }

      const circleTimer = setInterval(() => {
        timeStamp += 1;
        const secondsPassed = timeStamp / 10;
        const myExpoVal = easeInOutSine(secondsPassed, 20, radiusDef, 5);

        if (myExpoVal >= radiusDef) {
          fullRadius = true;
        }
        if (!fullRadius) {
          circleOption.radius = myExpoVal;
        } else {
          if (nbResponder === 0) {
            circleOption.strokeColor = "#d20536";
          }
          circleOption.strokeWeight = 1;
          clearInterval(circleTimer);
        }
        circleAlert.current.setOptions(circleOption);
      }, 100);
    } else {
      circleOption.strokeWeight = 1;
      circleOption.radius = radiusDef;
      circleAlert.current.setOptions(circleOption);
    }
  }

  /**
   * Désélectionne visuellement tous les icônes et réattribue les events "hover"
   */
  function unselectAedIcons() {
    if (markersAedsRef.current.length) {
      markersAedsRef.current.forEach((marker, index) => {
        //TODO voir crash ici !
        let withPhone = "";
        if (currentAedsList[index] !== undefined) {
          withPhone = currentAedsList[index].phoneNumber;
        }

        marker.setIcon(withPhone ? iconFormatter(iconAEDWithPhone) : iconFormatter(iconAED));

        googleMapsApi.event.addListener(marker, "mouseover", () => {
          marker.setIcon(
            withPhone ? iconFormatter(iconAEDWithPhoneHovered) : iconFormatter(iconAEDHovered)
          );
        });

        googleMapsApi.event.addListener(marker, "mouseout", () => {
          marker.setIcon(withPhone ? iconFormatter(iconAEDWithPhone) : iconFormatter(iconAED));
        });
      });
    }
  }

  return (
    <div id="map" style={{ width: "100%", height: "calc(100vh - 5.2rem)" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.GMAP_KEY,
          libraries: ["places", "geometry", "visualization"],
          language: t("language"),
          region: t("region"),
        }}
        defaultCenter={{ lat: 45.751044, lng: 3.084851 }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps: googleMapsApi }) => apiHasLoaded(map, googleMapsApi)}
      />
      {aedPopin ? (
        <SoftBox position="absolute" width="320px" height="400px" top={135} right={10}>
          <AedCard aed={aedPopin} />
        </SoftBox>
      ) : null}
      <AddressTooFarDialog
        open={openDialogAddressTooFar}
        onClose={(answer) => dialogAddressTooFarAnswerHandler(answer)}
      />
      <UnclearAddressDialog
        open={openDialogUnclearAddress}
        onClose={() => setOpenDialogUnclearAddress(false)}
      />
      <UpdateAddressDialog
        newAddress={potentialNewAddress?.address}
        onClose={(address) => dialogUpdateAddressAnswerHandler(address)}
      />
    </div>
  );
}
