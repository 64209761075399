import { alertConstants } from "../constants";

const defaultOptions = {
  config: {
    debugMode: 1,
    positionInit: { lat: 45.751044, lng: 3.084851 },
    zoomInit: 11,
  },
};

const defaultAlert = {
  alertId: 0,
};

export default (
  state = {
    options: defaultOptions,
    alert: defaultAlert,
    aed: [],
    aedSelected: 0,
    countrySelected: "FR",
  },
  action = {}
) => {
  switch (action.type) {
    case alertConstants.OPTION_SUCCESS:
      let countrySelected = "FR";
      if (action.payload.countries) {
        countrySelected = action.payload?.countries[0]?.code || "FR";
      }
      return {
        ...state,
        options: action.payload,
        countrySelected: countrySelected,
      };
    case alertConstants.SETTINGUP_SUCCESS:
      return {
        ...state,
        alert: { ...state.alert, ...action.payload.alert, wrongAddress: false },
        aed: action.payload.aed,
      };
    case alertConstants.ALERT_SUCCESS:
      let aedsList = {};
      if (action.payload.aed) {
        aedsList = { aed: action.payload.aed };
      }
      return {
        ...state,
        alert: { ...state.alert, ...action.payload.alert },
        ...aedsList,
      };
    case alertConstants.UPDT_SUCCESS:
      return {
        ...state,
        alert: { ...state.alert, ...action.payload.alert },
      };
    case alertConstants.CLOSE_SUCCESS:
      return {
        ...state,
        alert: defaultAlert,
        citizenResponder: [],
        aed: [],
      };
    case alertConstants.STOPPED_SUCCESS:
      return {
        ...state,
        citizenResponder: action.payload.citizenResponder,
      };
    case alertConstants.STARTED_SUCCESS:
      return {
        ...state,
        citizenResponder: action.payload.citizenResponder,
      };
    case alertConstants.ONCALL_SUCCESS:
      return {
        ...state,
        citizenResponder: action.payload.citizenResponder,
      };
    case alertConstants.POPULATE_SUCCESS:
      return {
        ...state,
      };
    case alertConstants.POPULATE_AED_SELECTED_SUCCESS:
      return {
        ...state,
        aedSelected: action.payload,
      };
    case alertConstants.COUNTRY_SELECTED_SUCCESS:
      return {
        ...state,
        countrySelected: action.payload,
      };
    case alertConstants.WRONG_ADDRESS:
      return {
        ...state,
        alert: { ...state.alert, wrongAddress: action.payload },
      };
    default:
      return state;
  }
};
