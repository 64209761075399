/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import FDBSReportsDoughnutChartItem from "./FDBSReportsDoughnutChartItem";

// ReportsDoughnutChart configurations
import configs from "./configs";

function FDBSReportsDoughnutChart({ count, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const renderItems =
    chart.labels && chart.datasets
      ? chart.labels.map((label, key) => (
          <FDBSReportsDoughnutChartItem
            color={chart.datasets.backgroundColors ? chart.datasets.backgroundColors[key] : "dark"}
            title={label}
            key={label}
            value={chart.datasets.data ? chart.datasets.data[key] : 0}
            percentage={
              chart.datasets.data
                ? Math.round(
                    (chart.datasets.data[key] / chart.datasets.data.reduce((a, b) => a + b, 0)) *
                      100
                  )
                : 0
            }
            hasBorder={key !== chart.labels.length - 1}
          />
        ))
      : null;

  return (
    <SoftBox pt={3}>
      {useMemo(
        () => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SoftBox height="200px" textAlign="center" position="relative">
                <SoftBox height={{ xs: "65%", sm: "100%" }} mt={{ xs: 6, sm: 0 }}>
                  <Doughnut data={data} options={options} />
                </SoftBox>
                <SoftBox
                  mt={{ xs: 0, sm: -15.25 }}
                  position="relative"
                  top={{ xs: "-8.25rem", sm: 0 }}
                >
                  <SoftTypography color="text">{count.text}</SoftTypography>
                  <SoftTypography fontWeight="medium">{count.number}</SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              {renderItems}
            </Grid>
          </Grid>
        ),
        [chart]
      )}
    </SoftBox>
  );
}

// Setting default values for the props of ReportsDoughnutChart
FDBSReportsDoughnutChart.defaultProps = {
  tooltip: "",
};

// Typechecking props for the ReportsDoughnutChart
FDBSReportsDoughnutChart.propTypes = {
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string.isRequired,
  }).isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
    ).isRequired,
  }).isRequired,
};

export default FDBSReportsDoughnutChart;
