import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SoftButton from "components/SoftButton";

export default function AlertClosedDialog({ open }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ padding: "35px 24px 16px 24px" }}>
        {t("alert.dialog.closed.title")}
      </DialogTitle>
      <DialogContent sx={{ padding: "35px 24px" }}>
        <DialogContentText sx={{ marginBottom: "24px" }}>
          {t("alert.dialog.closed.content")}
        </DialogContentText>
        <SoftButton
          variant="contained"
          color="primary"
          sx={{ "& svg": { fontSize: "1.5rem!important" } }}
          onClick={() => navigate(`/dashboards/default`)}
        >
          <AutorenewIcon sx={{ marginRight: "8px" }} /> {t("alert.dialog.closed.button")}
        </SoftButton>
      </DialogContent>
    </Dialog>
  );
}
