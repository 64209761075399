import { useState } from "react";
import { useSelector } from "react-redux";

import AlertForm from "./AlertForm/AlertForm";
import AlertFormOverview from "./AlertFormOverview/AlertFormOverview";

export default function AlertFormLayout({ onChange, onSubmit }) {
  const currentAlert = useSelector((store) => store.alertState.alert);
  const [updateMode, setUpdateMode] = useState(false);

  async function handleSubmit(updates) {
    if (updateMode) {
      setUpdateMode(false);
      return true;
    } else {
      return await onSubmit(updates);
    }
  }

  if (currentAlert.status === 2 && !updateMode) {
    return <AlertFormOverview onWantToUpdate={() => setUpdateMode(true)} />;
  }
  return (
    <AlertForm
      onChange={onChange}
      onSubmit={(updates) => handleSubmit(updates)}
      onCancel={() => setUpdateMode(false)}
    />
  );
}
