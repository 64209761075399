import API from "helpers/api";
import config from "config.js";

const myDomain = window.location.host;

const forgotPassword = async (email) => {
  const baseUrl = `${config.PROTOCOL}://${myDomain}/authentication/renew-password`;
  console.log(baseUrl);
  const path = `/services/backoffice/password/forgot`;
  const idSite = config.BACKEND_ID;
  const res = await API.post(path, { email, baseUrl, idSite });
  const dt = res.data.data;
  return dt;
};

const renewPassword = async (token, password, idUser) => {
  const baseUrl = `${config.PROTOCOL}://${myDomain}/authentication/sign-in`;
  const path = `/services/backoffice/password/renew`;
  const idSite = config.BACKEND_ID;
  const res = await API.post(path, { token, password, idUser, idSite, baseUrl });
  const dt = res.data.data;
  return dt;
};

const verifyToken = async (token, idUser) => {
  const path = "/services/backoffice/password/verifyToken";
  const res = await API.post(path, { token, idUser });
  const dt = res.data.data;
  return dt;
};

export const passwordService = {
  forgotPassword,
  renewPassword,
  verifyToken,
};
