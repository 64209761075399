import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import OngoingAlertCard from "./OngoingAlertsCard/OngoingAlertsCard";

export default function OngoingAlertsListDialog({ alerts = [], onClose, open }) {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      onClose={() => onClose()}
      open={open}
      sx={{ "& .MuiPaper-root": { padding: "41px 41px 21px 41px" } }}
    >
      <DialogTitle
        sx={{
          padding: "12px 0px",
          textAlign: alerts.length ? "left" : "center",
        }}
      >
        <SoftTypography variant="h5" component="span">
          {t("ongoingAlerts.dialog.title", {
            nbAlerts: alerts.length,
            s: alerts.length > 1 ? "s" : "",
          })}
        </SoftTypography>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0" }}>
        <>
          {alerts.map((alert) => (
            <OngoingAlertCard key={alert.id} alert={alert} onSelect={() => onClose(alert)} />
          ))}
          <SoftBox p={1} sx={{ textAlign: "center" }}>
            <SoftButton onClick={() => onClose(0)} color="primary" startIcon={<AddIcon />}>
              {t("ongoingAlerts.dialog.button.newAlert")}
            </SoftButton>
          </SoftBox>
        </>
      </DialogContent>
    </Dialog>
  );
}
