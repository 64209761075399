import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SvgIcon, useMediaQuery, useTheme } from "@mui/material";

import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import OngoingAlertsListDialog from "./OngoingAlertsListDialog/OngoingAlertsListDialog";

export default function OngoingAlertsButton() {
  const { t } = useTranslation();
  const alerts = useSelector((store) => store.ongoingAlertsState.alerts);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClose(alert = undefined) {
    setOpenDialog(false);
    if (alert) {
      navigate(`/dashboards/default?alertId=${alert.id}`);
    } else {
      if (alert === 0) {
        navigate(`/dashboards/default`);
      }
    }
  }

  return (
    <>
      <SoftButton
        id="ongoing-alerts-button"
        onClick={() => setOpenDialog(true)}
        variant="outlined"
        color="dark"
        pl={2}
        sx={{ "& svg": { fontSize: "1.5rem!important" } }}
      >
        <SvgIcon>
          <path
            d="M19.875 8.42109H14.7117L19.3546 2.55469C19.4507 2.43047 19.364 2.25 19.207 2.25H10.2187C10.1531 2.25 10.0898 2.28516 10.057 2.34375L3.98433 12.832C3.91167 12.9563 4.00073 13.1133 4.14605 13.1133H8.23355L6.13823 21.4945C6.0937 21.6773 6.31402 21.8062 6.44995 21.675L20.0039 8.74219C20.1257 8.62734 20.0437 8.42109 19.875 8.42109ZM8.86402 17.168L10.2773 11.5195H6.58823L11.032 3.84609H16.296L11.414 10.0172H16.3593L8.86402 17.168Z"
            fill="#384764"
          />
        </SvgIcon>
        <SoftTypography pl={1} fontWeight="regular" textTransform="none" fontSize="1rem">
          {alerts.length}
        </SoftTypography>
        {!smallScreen && (
          <SoftTypography pl={0.5} fontWeight="regular" textTransform="none" fontSize="1rem">
            {t("ongoingAlerts.button.mainText", { s: alerts.length > 1 ? "s" : "" })}
          </SoftTypography>
        )}
        {!smallScreen && alerts.length !== 0 && (
          <SoftTypography
            pl={1}
            fontWeight="regular"
            textTransform="none"
            fontSize="1rem"
            color="primary"
            sx={{ textDecoration: "underline" }}
          >
            {t("ongoingAlerts.button.cta")}
          </SoftTypography>
        )}
      </SoftButton>
      <OngoingAlertsListDialog
        alerts={alerts}
        open={openDialog}
        onClose={(alert) => handleClose(alert)}
      />
    </>
  );
}
