import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { CircularProgress, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

import { indicatorsConstants as constants } from "constants";
import useGetCitizensRespondersGrowth from "hooks/indicators/useGetCitizensRespondersGrowth";

export default function CitizensRespondersGrowthCard({ filter = { area: "" } }) {
  const { t } = useTranslation();
  const [displayModeFilter, setDisplayModeFilter] = useState(constants.NEW);
  const [temporalityFilter, setTemporalityFilter] = useState(constants.MONTH);
  const statsGrowth = useGetCitizensRespondersGrowth({
    area: filter.area,
    displayMode: displayModeFilter,
    temporality: temporalityFilter,
  });
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  const rangesList = [
    {
      name: t("dashboard.monitoringCitizensResponders.growth.range.day"),
      value: constants.WEEK,
    },
    {
      name: t("dashboard.monitoringCitizensResponders.growth.range.month"),
      value: constants.MONTH,
    },
    {
      name: t("dashboard.monitoringCitizensResponders.growth.range.year"),
      value: constants.YEAR,
    },
  ];

  function statsIsOK() {
    if (
      statsGrowth &&
      statsGrowth.temporality === temporalityFilter &&
      statsGrowth.displayMode === displayModeFilter
    ) {
      return true;
    }
    return false;
  }

  return (
    <SoftBox
      p={3}
      sx={{
        backgroundColor: (theme) => theme.palette.white.main,
        borderRadius: "16px",
        boxShadow: "0px 2px 7px rgba(56, 71, 100, 0.3)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <SoftBox sx={{ display: "flex", flexDirection: "column" }}>
          <SoftTypography variant="h5">
            {t("dashboard.monitoringCitizensResponders.growth.title")}
          </SoftTypography>
          <SoftBox display="flex" alignItems="center">
            <SoftTypography component="span">
              {t("dashboard.monitoringCitizensResponders.growth.aggregate")}
            </SoftTypography>
            <Switch
              checked={displayModeFilter !== constants.ACCURED}
              onChange={() =>
                setDisplayModeFilter(
                  displayModeFilter === constants.ACCURED ? constants.NEW : constants.ACCURED
                )
              }
              sx={{ marginX: "0.625rem" }}
            />
            <SoftTypography component="span">
              {t("dashboard.monitoringCitizensResponders.growth.new")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox>
          <SoftBox
            px={0.5}
            py={0.75}
            sx={{
              backgroundColor: (theme) => theme.palette.grey[100],
              borderRadius: "9px",
              "& .MuiButtonBase-root": { margin: "2px", minWidth: "120px" },
            }}
          >
            {rangesList.map((range) => (
              <SoftButton
                key={range.value}
                size="small"
                data-value={range.value}
                onClick={(event) => setTemporalityFilter(event.target.dataset.value)}
                variant={temporalityFilter === range.value ? "contained" : "text"}
                color={temporalityFilter === range.value ? "primary" : "dark"}
                sx={{ fontSize: "1rem", minWidth: "100px!important" }}
              >
                {range.name}
              </SoftButton>
            ))}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {statsIsOK() ? (
        <>
          <VerticalBarChart
            chart={{
              labels:
                temporalityFilter === constants.YEAR
                  ? statsGrowth.labels
                  : statsGrowth.labels.map((label) => t(`dashboard.labels.${label}`)),
              datasets: [{ label: "Total", color: "dark", data: statsGrowth.datasets }],
            }}
          />
          <SoftTypography variant="h5" sx={{ textAlign: "center" }}>
            {temporalityFilter === constants.YEAR
              ? t("dashboard.monitoringCitizensResponders.growth.temporalityYear", {
                  yearStart: statsGrowth.labels[0],
                })
              : t("dashboard.monitoringCitizensResponders.growth.temporality", {
                  dateStart: statsGrowth.dateStart
                    ? statsGrowth.dateStart
                    : new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                        dateStyle: "short",
                      }).format(
                        Date.now() -
                          1000 * 3600 * 24 * (temporalityFilter === constants.WEEK ? 7 : 365)
                      ),
                  dateEnd: statsGrowth.dateEnd
                    ? statsGrowth.dateEnd
                    : new Intl.DateTimeFormat(getCurrentLng().substring(0, 2), {
                        dateStyle: "short",
                      }).format(Date.now()),
                })}
          </SoftTypography>
        </>
      ) : (
        <SoftBox
          sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
          my={5}
        >
          <CircularProgress />
        </SoftBox>
      )}
    </SoftBox>
  );
}
