/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default [
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        lightness: "6",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "all",
    stylers: [
      {
        saturation: "-78",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "-45",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: "45",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#9dc0f9",
      },
      {
        lightness: "52",
      },
    ],
  },
];

// export default [
//   {
//     featureType: "landscape",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 65,
//       },
//       {
//         visibility: "on",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 51,
//       },
//       {
//         visibility: "simplified",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         visibility: "simplified",
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 30,
//       },
//       {
//         visibility: "on",
//       },
//     ],
//   },
//   {
//     featureType: "road.local",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 40,
//       },
//       {
//         visibility: "on",
//       },
//     ],
//   },
//   {
//     featureType: "transit",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         visibility: "simplified",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.province",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "labels",
//     stylers: [
//       {
//         visibility: "on",
//       },
//       {
//         lightness: -25,
//       },
//       {
//         saturation: -100,
//       },
//     ],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [
//       {
//         hue: "#ffff00",
//       },
//       {
//         lightness: -25,
//       },
//       {
//         saturation: -97,
//       },
//     ],
//   },
// ];
