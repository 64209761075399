import { useEffect, useState } from "react";

import { indicatorsConstants as constants } from "constants";
import { indicatorService } from "services";
import useErrorFormater from "hooks/useErrorFormater";

export default function useGetCitizensRespondersGrowth({
  area = "",
  displayMode = constants.ACCURED,
  temporality = constants.MONTH,
}) {
  const [addErrorMsg] = useErrorFormater();
  const [statsGrowth, setStatsGrowth] = useState(null);

  useEffect(() => {
    setStatsGrowth(null);
    indicatorService
      .getCitizensRespondersGrowth({ area, displayMode, temporality })
      .then((result) => {
        const resultWithTypeFilter = result;
        resultWithTypeFilter.temporality = temporality;
        resultWithTypeFilter.displayMode = displayMode;
        setStatsGrowth(resultWithTypeFilter);
      })
      .catch((error) => addErrorMsg("getCitizensRespondersGrowth", error));
  }, [area, displayMode, temporality]);

  return statsGrowth;
}
