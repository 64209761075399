import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftButton from "components/SoftButton";

export default function UnclearAddressDialog({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{ "& .MuiPaper-root": { padding: "2rem 1.5rem 1.5rem 1.5rem" }, textAlign: "center" }}
    >
      <DialogTitle>
        {t("alert.dialog.unclearAddress.title")}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "1.5rem" }}>
          {t("alert.dialog.unclearAddress.content")}
        </DialogContentText>
        <SoftButton variant="contained" color="primary" onClick={() => onClose()}>
          {t("alert.dialog.unclearAddress.button")}
        </SoftButton>
      </DialogContent>
    </Dialog>
  );
}
