import { forgotPassword } from "actions/passwordAction";

import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import { Link } from "react-router-dom";

import { useState } from "react";

// Translation components
import { useTranslation } from "react-i18next";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftAlert from "components/SoftAlert";

import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Authentication layout components
import IllustrationLayout from "fdbs/layouts/authentication/components/IllustrationLayout";

function Illustration() {
  const { t } = useTranslation();
  // const [valid, setValid] = useState(false);

  const dispatch = useDispatch();
  const message = useSelector((state) => state.messageState);

  const [loading, setLoading] = useState(false);

  const validateSchema = Yup.object({
    email: Yup.string().email(t("error.invalid-email-address")).required(t("error.required")),
  });

  const dispatchAction = (email) => {
    dispatch(forgotPassword(email))
      .then(() => {
        setLoading(false);
        Swal.fire(t("forgotPwd.title-validation"), t("forgotPwd.text-validation"), "success");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    dispatchAction(values.email);
  };

  return (
    <IllustrationLayout
      title={t("forgotPwd.title")}
      description={t("forgotPwd.description")}
      color="warning"
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form autoComplete="off">
            {message.msgType !== "" ? (
              <SoftAlert color={message.msgType}>
                <Icon fontSize="small" className="text-gradient-error">
                  {message.msgType}
                </Icon>
                <SoftTypography variant="body2" color="white" sx={{ marginLeft: "5px" }}>
                  {t(message.msg)}
                </SoftTypography>
              </SoftAlert>
            ) : null}
            <SoftBox>
              <SoftBox mb={2}>
                <Field
                  name="email"
                  as={SoftInput}
                  placeholder={t("forgotPwd.email")}
                  error={Boolean(errors.email)}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name="email" />
                  </SoftTypography>
                </SoftBox>{" "}
              </SoftBox>
              <SoftBox mt={4} mb={1}>
                <SoftButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color="light" size={18} sx={{ marginRight: "5px" }} />
                  ) : null}
                  {t("forgotPwd.bt-validate")}
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {t("forgotPwd.back-to-signin")}{" "}
                <SoftTypography
                  component={Link}
                  to="/authentication/signin"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("forgotPwd.click-here")}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Form>
        )}
      </Formik>
    </IllustrationLayout>
  );
}

export default Illustration;
