import { useEffect, useState } from "react";
import { indicatorService } from "services";

import useErrorFormater from "hooks/useErrorFormater";

export default function useGetAlertsRateRanking() {
  const [addErrorMsg] = useErrorFormater();
  const [stats, setStats] = useState();

  useEffect(() => {
    indicatorService
      .getAlertsRateRanking()
      .then((result) => {
        setStats(result);
      })
      .catch((error) => addErrorMsg("getAlertsRateRanking", error));
  }, []);

  return stats;
}
