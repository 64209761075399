import { useEffect, useState } from "react";
import i18n from "i18n";

import { indicatorService } from "services";
import useErrorFormater from "hooks/useErrorFormater";

export default function useGetCitizensRespondersSkills({ area = "" }) {
  const [addErrorMsg] = useErrorFormater();
  const [statsSkills, setStatsSkills] = useState();

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  useEffect(() => {
    indicatorService
      .getCitizensRespondersSkills({ area, lang: getCurrentLng() })
      .then((result) => {
        setStatsSkills(result);
      })
      .catch((error) => addErrorMsg("getCitizensRespondersSkills", error));
  }, [area, i18n.language]);

  return statsSkills;
}
