import API from "helpers/api";
// import { authHeader } from "../helpers/authHeader";

const reportProblem = async (idData, problem, email) => {
  const path = `/services/backOffice/aed/reportProblem`;

  const res = await API.post(path, {
    idData,
    problem,
    email,
  });
  const dt = res.data.data;
  return dt;
};

export const aedService = {
  reportProblem,
};
