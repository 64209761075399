import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ComparisonAlerts from "./ComparisonAlerts/ComparisonAlerts";
import ComparisonCitizensResponders from "./ComparisonCitizensResponders/ComparisonCitizensResponders";

export default function ComparisonsSection() {
  const { t } = useTranslation();

  return (
    <SoftBox component="section" py={5}>
      <SoftTypography variant="h4">{t("dashboard.comparisons.title")}</SoftTypography>
      <Grid container spacing={3} alignItems="stretch" pt={4}>
        <Grid item xs={6}>
          <ComparisonAlerts />
        </Grid>
        <Grid item xs={6}>
          <ComparisonCitizensResponders />
        </Grid>
      </Grid>
    </SoftBox>
  );
}
