function unconfirm() {
  return {
    color: "red",
    fontWeight: "100",
  };
}

function masterCard() {
  return {
    overflow: "visible !important",
  };
}

function testModeCard() {
  return {
    padding: "10px",
    opacity: "0.6",
  };
}

function testModeBlock() {
  return {
    left: "calc(50% - 10rem)",
  };
}

export { unconfirm, masterCard, testModeCard, testModeBlock };
