import { apiErrorManager } from "helpers/apiErrorManager";
import {
  alertConstants,
  fakeAlertResult,
  fakeBSPositions,
  fakeSettingUpResult,
  messageConstants,
} from "../constants";
import { alertService } from "../services";

function optionSuccess(values) {
  return { type: alertConstants.OPTION_SUCCESS, payload: values };
}
function settingupSuccess(values) {
  return { type: alertConstants.SETTINGUP_SUCCESS, payload: values };
}
function alertSuccess(values) {
  return { type: alertConstants.ALERT_SUCCESS, payload: values };
}
function onCallSuccess(values) {
  return { type: alertConstants.ONCALL_SUCCESS, payload: values };
}
function updtSuccess(values) {
  return { type: alertConstants.UPDT_SUCCESS, payload: values };
}
function closeSuccess(options) {
  return { type: alertConstants.CLOSE_SUCCESS, payload: options };
}
function populateSuccess(values) {
  return { type: alertConstants.POPULATE_SUCCESS, payload: values };
}
function alertAedSelectedSuccess(values) {
  return { type: alertConstants.POPULATE_AED_SELECTED_SUCCESS, payload: values };
}
function alertCountrySelectedSuccess(values) {
  return { type: alertConstants.COUNTRY_SELECTED_SUCCESS, payload: values };
}
function setWrongAddress(values) {
  return { type: alertConstants.WRONG_ADDRESS, payload: values };
}
function stoppedSuccess(values) {
  return { type: alertConstants.STOPPED_SUCCESS, payload: values };
}
function startedSuccess(values) {
  return { type: alertConstants.STARTED_SUCCESS, payload: values };
}

function message(msgType, msg) {
  const payload = { msgType, msg };
  return { type: messageConstants.SET_MESSAGE, payload };
}

export const getAlertOption = () => (dispatch) => {
  return alertService.getAlertOptions().then(
    (options) => {
      dispatch(optionSuccess(options));
      dispatch(message("", ""));
      return Promise.resolve(options);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const settingUpAlert =
  (lon, lat, address, addressInfo, countryCode, alertType, alertId) => (dispatch) => {
    return alertService
      .settingUpAlert(lon, lat, address, addressInfo, countryCode, alertType, alertId)
      .then(
        (settingUpalertResult) => {
          dispatch(settingupSuccess(settingUpalertResult));
          dispatch(message("", ""));
          return Promise.resolve(settingUpalertResult);
        },
        (error) => {
          const errorMsg = apiErrorManager(error);
          dispatch(message("error", errorMsg));
          return Promise.reject();
        }
      );
  };

export const settingUpAlertDemo =
  (lon, lat, address, addressInfo, countryCode, alertType, alertId) => (dispatch) => {
    const result = fakeSettingUpResult;
    result.addressInfo = addressInfo;
    dispatch(settingupSuccess(result));
    dispatch(message("", ""));
    return Promise.resolve(result);
  };

export const populateInListener = () => (dispatch, getState) => {
  dispatch(populateSuccess());
  return getState().alertState;
};

export const alertAedSelected = (idAed) => (dispatch, getState) => {
  dispatch(alertAedSelectedSuccess(idAed));
  return getState().alertState;
};

export const alertCountrySelected = (country) => (dispatch, getState) => {
  dispatch(alertCountrySelectedSuccess(country));
  return getState().alertState;
};

export const alert = (alertId, lon, lat, address, addressInfo, alertType) => (dispatch) => {
  return alertService.alert(alertId, lon, lat, address, addressInfo, alertType).then(
    (alertResult) => {
      dispatch(message("", ""));
      dispatch(alertSuccess(alertResult));
      return Promise.resolve(alertResult.alert);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};
let nbCall = 0;

export const alertDemo = () => (dispatch) => {
  const fakeAlertResultUpdated = fakeAlertResult;
  fakeAlertResultUpdated.alert.creationDate = new Date();
  dispatch(message("", ""));
  dispatch(alertSuccess(fakeAlertResultUpdated));
  return Promise.resolve(fakeAlertResultUpdated.alert);
};

export const getAlert = (alertId) => (dispatch) => {
  return alertService.getAlert(alertId).then(
    (alertResult) => {
      dispatch(message("", ""));
      dispatch(alertSuccess(alertResult));
      return Promise.resolve(alertResult);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const updtAlert = (alertId, lon, lat, address, addressInfo) => (dispatch) => {
  return alertService.updtAlert(alertId, lon, lat, address, addressInfo).then(
    (updtAlertResult) => {
      dispatch(updtSuccess({ alert: { lon, lat, address, addressInfo } }));
      dispatch(message("", ""));
      return Promise.resolve(updtAlertResult);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const onCall = (alertId) => (dispatch) => {
  return alertService.onCall(alertId).then(
    (onCallResult) => {
      dispatch(onCallSuccess(onCallResult));
      dispatch(message("", ""));
      return Promise.resolve(onCallResult);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const onCallDemo = (alertId) => (dispatch) => {
  if (nbCall === fakeBSPositions.length) {
    nbCall--;
  }
  dispatch(onCallSuccess(fakeBSPositions[nbCall].data));
  dispatch(message("", ""));
  nbCall++;
  return Promise.resolve(fakeBSPositions[nbCall - 1]);
};

export const closeAlert = (alertId) => (dispatch) => {
  return alertService.closeAlert(alertId).then(
    () => {
      dispatch(closeSuccess());
      dispatch(message("", ""));
      return Promise.resolve();
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const closeAlertDemo = () => (dispatch) => {
  dispatch(closeSuccess());
  dispatch(message("", ""));
  return Promise.resolve();
};

export const unloadAlert = () => (dispatch) => {
  dispatch(closeSuccess());
};

export const stoppedCr = (alertId, id) => (dispatch) => {
  return alertService.stopCitizenResponder(alertId, id).then(
    (onCallResult) => {
      dispatch(stoppedSuccess(onCallResult));
      dispatch(message("", ""));
      return Promise.resolve(onCallResult);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const startedCr = (alertId, id) => (dispatch) => {
  return alertService.startCitizenResponder(alertId, id).then(
    (onCallResult) => {
      dispatch(startedSuccess(onCallResult));
      dispatch(message("", ""));
      return Promise.resolve(onCallResult);
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const wrongAddress = () => (dispatch) => {
  dispatch(setWrongAddress(true));
};
