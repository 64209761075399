import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

export default function UpdateAddressDialog({ newAddress, onClose }) {
  const { t } = useTranslation();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const [addressSelected, setAddressSelected] = useState();

  const addresses = [
    { label: t("alert.dialog.updateAddress.initialAddress"), address: currentAlert.address },
    { label: t("alert.dialog.updateAddress.newAddress"), address: newAddress },
  ];

  useEffect(() => {
    setAddressSelected();
  }, [newAddress]);

  if (currentAlert.address && newAddress) {
    return (
      <Dialog open={newAddress !== undefined} fullWidth>
        <DialogTitle sx={{ padding: "35px 24px 16px 24px" }}>
          {t("alert.dialog.updateAddress.title")}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "35px 24px" }}>
          <DialogContentText>{t("alert.dialog.updateAddress.content")}</DialogContentText>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ paddingY: "1.5rem" }}
            value={addressSelected ? addressSelected : null}
            onChange={(event) => setAddressSelected(event.target.value)}
          >
            {addresses.map(({ label, address }) => (
              <SoftBox
                key={label}
                py={0.5}
                px={2}
                sx={{
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.grey[100],
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Radio
                  value={address}
                  sx={{
                    marginRight: "0.675rem",
                    borderColor: (theme) => theme.palette.dark.main,
                    backgroundColor: (theme) => theme.palette.white.main,
                  }}
                />
                <SoftBox>
                  <SoftTypography sx={{ marginBottom: "0.5rem" }}>{label}</SoftTypography>
                  <SoftTypography sx={{ color: (theme) => theme.palette.grey[500] }}>
                    {address}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ))}
          </RadioGroup>
          <SoftButton
            variant="outlined"
            color="dark"
            sx={{ marginRight: "12px" }}
            onClick={() => onClose()}
          >
            {t("alert.dialog.updateAddress.button.cancel")}
          </SoftButton>
          <SoftButton
            variant="contained"
            color={addressSelected ? "primary" : "secondary"}
            disabled={addressSelected ? false : true}
            onClick={() => onClose(addressSelected)}
          >
            {t("alert.dialog.updateAddress.button.confirm")}
          </SoftButton>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
}
