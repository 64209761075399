import {
  alert,
  alertDemo,
  closeAlert,
  closeAlertDemo,
  onCall,
  onCallDemo,
  settingUpAlert,
  settingUpAlertDemo,
  updtAlert,
} from "actions/alertAction";
import AlertPage from "./Alert.page";

const withActions =
  (WrappedAlertPage, isDemoMode = false) =>
  (props) => {
    const actions = isDemoMode
      ? {
          alert: alertDemo,
          closeAlertAction: closeAlertDemo,
          onCall: onCallDemo,
          settingUpAlert: settingUpAlertDemo,
          updtAlert: updtAlert,
        }
      : {
          alert: alert,
          closeAlertAction: closeAlert,
          onCall: onCall,
          settingUpAlert: settingUpAlert,
          updtAlert: updtAlert,
        };
    return <WrappedAlertPage actions={actions} isDemoMode={isDemoMode} {...props} />;
  };

export const AlertPageWithActions = withActions(AlertPage);
export const AlertPageWithDemoActions = withActions(AlertPage, true);
