import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { testModeBlock, testModeCard } from "fdbs/layouts/dashboards/alert/styles";

export default function TestBanner() {
  const { t } = useTranslation();
  const currentOptions = useSelector((state) => state.alertState.options);
  if (currentOptions.config.debugMode > 0) {
    return (
      <SoftBox position="absolute" height="100px" top={100} sx={testModeBlock()}>
        <Card sx={testModeCard()}>
          <SoftTypography variant="h2" fontWeight="bold" color="primary" textTransform="uppercase">
            {t(`alert.debugMode.${currentOptions.config.debugMode}`)}
          </SoftTypography>
        </Card>
      </SoftBox>
    );
  }
  return null;
}
