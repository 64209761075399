import { apiErrorManager } from "helpers/apiErrorManager";
import { userService } from "../services";
import { userConstants, messageConstants } from "../constants";

function success(user) {
  return { type: userConstants.LOGIN_SUCCESS, payload: user };
}
function failure(error) {
  return { type: userConstants.LOGIN_FAILURE, payload: error };
}
function updtPwdSuccess(user) {
  return { type: userConstants.UPDT_PWD_SUCCESS, payload: user };
}

function message(msgType, msg) {
  const payload = { msgType, msg };
  return { type: messageConstants.SET_MESSAGE, payload };
}

// function failureMessage(message) {
//   return { type: messageConstants.SET_MESSAGE, payload: message };
// }
// function successMessage(message) {
//   return { type: messageConstants.SET_MESSAGE, payload: message };
// }

export const login = (email, password) => (dispatch) => {
  return userService.login(email, password).then(
    (user) => {
      dispatch(message("", ""));
      dispatch(success(user));
      return Promise.resolve();
    },
    (error) => {
      const errMsg = apiErrorManager(error);
      dispatch(message("error", errMsg));
      dispatch(failure(error));
      return Promise.reject();
    }
  );
};

export const updtPwd = (newPwd) => (dispatch) => {
  return userService.updtPwd(newPwd).then(
    (updtPwdResult) => {
      dispatch(message("", ""));
      dispatch(updtPwdSuccess(updtPwdResult));
      return Promise.resolve();
    },
    (error) => {
      const errorMsg = apiErrorManager(error);
      dispatch(message("error", errorMsg));
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  dispatch(message("", ""));
  userService.logout();
  dispatch({
    type: userConstants.LOGOUT,
    payload: [],
  });
  return Promise.resolve();
};
