function navbar(theme) {
  const { palette, functions, transitions } = theme;
  const { dark, grey, white } = palette;
  const { rgba, pxToRem } = functions;

  return {
    backgroundColor: rgba(white.main, 1),
    borderBottom: "1px solid",
    borderColor: grey[100],
    color: dark.main,
    top: pxToRem(0),
    height: "80px",
    display: "grid",
    alignItems: "center",
    paddingLeft: pxToRem(24),

    "& > *": {
      transition: transitions.create("all", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
}

const navbarContainer = ({ breakpoints }) => ({
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",

  [breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    padding: "0",
  },
  height: "100%",
});

const navbarRow = ({ breakpoints }, { isMini }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  [breakpoints.up("md")]: {
    justifyContent: isMini ? "space-between" : "stretch",
    width: isMini ? "100%" : "max-content",
  },

  [breakpoints.up("xl")]: {
    justifyContent: "stretch !important",
    width: "max-content !important",
  },
});

const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 0.75,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

const navbarDesktopMenu = ({ breakpoints }) => ({
  display: "none !important",
  cursor: "pointer",

  [breakpoints.up("xl")]: {
    display: "inline-block !important",
  },
});

const navbarMobileMenu = ({ breakpoints }) => ({
  display: "inline-block",
  lineHeight: 0,

  [breakpoints.up("xl")]: {
    display: "none",
  },
});

const bellAnimation = () => ({
  color: "red",
  animationName: "$swing",
  animationIterationCount: "infinite",
  animationDuration: "2s",
});

function bellStop(theme) {
  const { palette } = theme;
  const { text } = palette;

  return {
    color: text.main,
  };
}

// "@keyframes swing": {
//   "2%": {
//     transform: "rotate3d(0, 0, 1, 35deg)",
//   },
//   "16%": {
//     transform: "rotate3d(0, 0, 1, -30deg)",
//   },
//   "24%": {
//     transform: "rotate3d(0, 0, 1, 5deg)",
//   },
//   "32%": {
//     transform: "rotate3d(0, 0, 1, -5deg)",
//   },
//   "40%": {
//     transform: "rotate3d(0, 0, 1, 0deg)",
//   },
// },
// };

export {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
  bellAnimation,
  bellStop,
};
