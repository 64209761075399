import { useEffect, useState } from "react";
import { indicatorService } from "services";

import useErrorFormater from "hooks/useErrorFormater";

export default function useGetLast24(filter) {
  const [addErrorMsg] = useErrorFormater();
  const [stats, setStats] = useState();

  useEffect(() => {
    indicatorService
      .getLast24(filter)
      .then((result) => {
        setStats(result);
      })
      .catch((error) => addErrorMsg("getLast24", error));
  }, [filter]);

  return stats;
}
