import { useEffect, useState } from "react";
import { indicatorService } from "services";

import useErrorFormater from "hooks/useErrorFormater";

export default function useGetCitizensRespondersRateRanking() {
  const [addErrorMsg] = useErrorFormater();
  const [stats, setStats] = useState();

  useEffect(() => {
    indicatorService
      .getCitizensRespondersRateRanking()
      .then((result) => {
        setStats(result);
      })
      .catch((error) => addErrorMsg("getCitizensRespondersRateRanking", error));
  }, []);

  return stats;
}
