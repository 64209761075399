import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftButton from "components/SoftButton";

export default function AddressTooFarDialog({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{ "& .MuiPaper-root": { padding: "2rem 1.5rem 1.5rem 1.5rem" } }}
    >
      <DialogTitle>
        {t("alert.dialog.addressTooFar.title")}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "1.5rem" }}>
          {t("alert.dialog.addressTooFar.content")}
        </DialogContentText>
        <SoftButton
          variant="outlined"
          color="dark"
          sx={{ marginRight: "0.75rem" }}
          onClick={() => onClose()}
        >
          {t("alert.dialog.addressTooFar.button.cancel")}
        </SoftButton>
        <SoftButton variant="contained" color="primary" onClick={() => onClose(true)}>
          {t("alert.dialog.addressTooFar.button.confirm")}
        </SoftButton>
      </DialogContent>
    </Dialog>
  );
}
