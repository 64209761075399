import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AlertsTypologiesCard from "./AlertsTypologiesCard/AlertsTypologiesCard";
import AlertsGrowthCard from "./AlertsGrowthCard/AlertsGrowthCard";

export default function MonitoringAlerts({ filter }) {
  const { t } = useTranslation();
  return (
    <SoftBox component="section" py={5}>
      <SoftTypography variant="h4">{t("dashboard.monitoringAlerts.title")}</SoftTypography>
      <Grid container spacing={3} alignItems="stretch" pt={4}>
        <Grid item xs={3} alignItems="stretch">
          <AlertsTypologiesCard filter={filter} />
        </Grid>
        <Grid item xs={9}>
          <AlertsGrowthCard filter={filter} />
        </Grid>
      </Grid>
    </SoftBox>
  );
}
