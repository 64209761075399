/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

import { setMessage } from "actions/messageAction";

// Soft UI Dashboard PRO React components
// import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import Configurator from "fdbs/elements/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routesList from "routes";

import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga4";

import useSiteWebSocket from "hooks/useSiteWebSocket";

export default function App() {
  const { t } = useTranslation();
  const [controller] = useSoftUIController();
  const { direction, layout } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const collapseName = pathname.split("/").slice(1)[0];
  const reduxDispatch = useDispatch();

  const currentUser = useSelector((state) => state.userState);

  let routes = routesList[0];

  if (currentUser.isLoggedIn === true) {
    if (routesList[currentUser.user.idGroup]) {
      routes = routesList[currentUser.user.idGroup];
    } else {
      const errorPage = 100002;
      const msgBundle = {
        msg: t("error.necessary-rights"),
        msgType: "error",
      };
      reduxDispatch(setMessage(msgBundle));
      routes = routesList[errorPage];
    }
  }

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  /** Récupération et abonnement au WebSocket général pour connaitre les notifications et les alertes en cours */
  useSiteWebSocket();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({ hitType: "pageview", page: pathname, title: layout });
      //ReactGA.set({ userId: currentUser.user.id });
    }
    if (currentUser.isLoggedIn) {
      Bugsnag.setUser(currentUser.user.id, currentUser.user.email, currentUser.user.accountName);
    } else {
      Bugsnag.setUser();
    }
  }, [currentUser.isLoggedIn]);

  if (!currentUser.isLoggedIn && collapseName !== "authentication") {
    return <Navigate to="/authentication/sign-in" replace />;
  }

  // if (currentUser.user.updatePwd === 1) {
  //   return <Navigate to="/authentication/renew-password" replace />;
  // }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && currentUser.isLoggedIn && <Configurator routes={routes} />}
        {layout === "vr"}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/default" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && currentUser.isLoggedIn && <Configurator routes={routes} />}
      {layout === "vr"}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/default" />} />
      </Routes>
    </ThemeProvider>
  );
}
