import API from "helpers/api";

const getAlertOptions = async () => {
  const path = `services/emergency/v2/options`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const settingUpAlert = async (lon, lat, address, addressInfo, countryCode, alertType, alertId) => {
  const path = `/services/emergency/v2/alertSettingUp`;
  const res = await API.put(path, {
    alertId,
    lon,
    lat,
    address,
    addressInfo,
    countryCode,
    alertType,
  });
  const dt = res.data.data;
  return dt;
};

const alert = async (alertId, lon, lat, address, addressInfo, alertType) => {
  const path = `/services/emergency/v2/alertLaunch`;
  const res = await API.post(path, {
    alertId,
    lon,
    lat,
    address,
    addressInfo,
    alertType,
  });
  const dt = res.data.data;
  return dt;
};

const getAlert = async (alertId) => {
  const path = `/services/emergency/v2/alerts/${alertId}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère une liste d'alertes
 * @param {Object} filter - Filtres d'alertes.
 * @param {Number} filter.close - État d'ouverture d'alerte désiré.
 * @param {Number} filter.status - Statut d'alerte désiré.
 * @returns {JSON} Retourne les alertes demandées.
 */
const getAlerts = async (filter = {}) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/emergency/v2/alerts${queryFilter ? `?${queryFilter}` : ""}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const onCall = async (alertId) => {
  const path = `/services/emergency/v2/citizenRespondersOnCall?alertId=${alertId}&lang=fr`; // TODO Add language
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const updtAlert = async (alertId, lon, lat, address, addressInfo) => {
  const path = `/services/emergency/v2/alert/${alertId}`;
  const res = await API.post(path, {
    lon,
    lat,
    address,
    addressInfo,
  });
  const dt = res.data.data;
  return dt;
};

const closeAlert = async (alertId) => {
  const path = `/services/emergency/v2/alertClose`;

  const res = await API.post(path, {
    alertId,
  });
  const dt = res.data.data;
  return dt;
};

const stopCitizenResponder = async (alertId, id) => {
  const path = `/services/emergency/v2/stopCitizenResponder`;

  const res = await API.post(path, {
    alertId,
    id,
  });
  const dt = res.data.data;
  return dt;
};

const startCitizenResponder = async (alertId, id) => {
  const path = `/services/emergency/v2/startCitizenResponder`;

  const res = await API.post(path, {
    alertId,
    id,
  });
  const dt = res.data.data;
  return dt;
};

export const alertService = {
  getAlertOptions,
  settingUpAlert,
  alert,
  getAlert,
  getAlerts,
  updtAlert,
  onCall,
  closeAlert,
  stopCitizenResponder,
  startCitizenResponder,
};
