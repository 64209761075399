export const apiErrorManager = (error) => {
  let message = "";
  console.log("---> error response ");
  console.log(error.response);
  // Network Error
  if (typeof error.response === "undefined") {
    message = "error.network";
  } else if (typeof error.response.data !== "undefined") {
    if (typeof error.response.data.data !== "undefined") {
      if (typeof error.response.data.data.msg !== "undefined") {
        message = error.response.data.data.msg;
      } else {
        message = "error.server-access-api";
      }
    } else {
      message = "error.server-access-api";
    }
  } else {
    message = "error.server-access";
  }

  return message;
};
