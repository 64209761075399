import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

import { onCallDemo } from "actions/alertAction";
import { closeAlertDemo } from "actions/alertAction";

export default function Tutorial({ alertChangeHandler, launchAlert }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isFinished, setIsFinished] = useState(false);
  const [startWriteAddress, setStartWriteAddress] = useState(false);
  const [startWriteDetail, setStartWriteDetail] = useState(false);
  const [startSimulateWebSocket, setStartSimulateWebsocket] = useState(false);

  const completeAddress = {
    lat: 48.87647150000001,
    lon: 2.330558,
    address: "3 Rue de Londres, 75009 Paris",
  };

  useEffect(() => {
    let fakeWebSocket;
    if (startSimulateWebSocket) {
      fakeWebSocket = setInterval(() => dispatch(onCallDemo()), 3000);
    }
    return () => clearInterval(fakeWebSocket);
  }, [startSimulateWebSocket]);

  useEffect(() => {
    let writer;
    if (startWriteAddress) {
      writer = setInterval(() => {
        if (document.getElementById("form-address")) {
          if (
            document.getElementById("form-address").value.length ===
              completeAddress.address.length ||
            isFinished
          ) {
            clearInterval(writer);
          } else {
            document.getElementById("form-address").value +=
              completeAddress.address[document.getElementById("form-address").value.length];
          }
        }
      }, 120);
    } else {
      clearInterval(writer);
    }
    return () => clearInterval(writer);
  }, [isFinished, startWriteAddress]);

  useEffect(() => {
    const detail = "Code interphone : 1234";
    let writer;
    if (startWriteDetail) {
      writer = setInterval(() => {
        if (document.getElementById("form-detail")) {
          if (document.getElementById("form-detail").value.length === detail.length || isFinished) {
            clearInterval(writer);
          } else {
            document.getElementById("form-detail").value +=
              detail[document.getElementById("form-detail").value.length];
          }
        }
      }, 120);
    } else {
      clearInterval(writer);
    }
    return () => clearInterval(writer);
  }, [isFinished, startWriteDetail]);

  return (
    <Joyride
      callback={(data) => {
        const { action, index, lifecycle, status } = data;
        // Etape 2 - Saisie adresse animée
        if (index === 1 && action === "update" && lifecycle === "tooltip") {
          setStartWriteAddress(true);
        }

        // Passage Etape 2 ---> Etape 3 - Envoi adresse pour créer une fausse alerte de démo.
        if (index === 2 && action === "next" && lifecycle === "ready") {
          alertChangeHandler(completeAddress);
        }

        // Passage Etape 6 ---> Etape 7 - Saisie d'un détail
        if (index === 5 && action === "next" && lifecycle === "ready") {
          setStartWriteDetail(true);
        }

        // Passage Etape 7 ---> Etape 8 - Envoi adresse pour créer une fausse alerte de démo.
        if (index === 7 && action === "next" && lifecycle === "ready") {
          launchAlert();
          setStartSimulateWebsocket(true);
        }

        // Arrêt du tutoriel
        if (action === "reset" && status === "ready") {
          setIsFinished(true);
          dispatch(closeAlertDemo());
          navigate(`/dashboards/default`);
        }
      }}
      continuous
      disableOverlayClose
      disableScrollParentFix
      hideCloseButton
      locale={{
        back: t("tutorial.back"),
        close: t("tutorial.close"),
        last: t("tutorial.last"),
        next: t("tutorial.next"),
        open: t("tutorial.open"),
        skip: t("tutorial.skip"),
      }}
      showProgress
      showSkipButton
      steps={[
        {
          placement: "center",
          target: "body",
          content: (
            <div>
              <b>{t("tutorial.step.0.title")}</b>
              <br /> <br />
              {t("tutorial.step.0.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: ".tuto-form-address > .MuiInputBase-root",
          content: (
            <div>
              <b>{t("tutorial.step.1.title")}</b>
              <br /> <br />
              {t("tutorial.step.1.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: "#map div div div div div div div div",
          content: (
            <div>
              <b>{t("tutorial.step.2.title")}</b>
              <br /> <br />
              {t("tutorial.step.2.content")}
            </div>
          ),
          disableBeacon: true,
          styles: {
            spotlight: { margin: "-70px -30px", padding: "50px 40px" },
          },
        },
        {
          target: "#potential-bs",
          content: (
            <div>
              <b>{t("tutorial.step.3.title")}</b>
              <br /> <br />
              {t("tutorial.step.3.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: ".tuto-form-type > div",
          content: (
            <div>
              <b>{t("tutorial.step.4.title")}</b>
              <br /> <br />
              {t("tutorial.step.4.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: ".tuto-form-detail > .MuiInputBase-root",
          content: (
            <div>
              <b>{t("tutorial.step.5.title")}</b>
              <br /> <br />
              {t("tutorial.step.5.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: ".tuto-form-submit",
          content: t("tutorial.step.6.content"),
          disableBeacon: true,
        },
        {
          target: "#bs-zone",
          content: (
            <div>
              <b>{t("tutorial.step.7.title")}</b>
              <br /> <br />
              {t("tutorial.step.7.content")}
            </div>
          ),
          placement: "right-start",
          disableBeacon: true,
        },
        {
          target: "#update-button",
          content: (
            <div>
              <b>{t("tutorial.step.8.title")}</b>
              <br /> <br />
              {t("tutorial.step.8.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: "#close-button",
          content: (
            <div>
              <b>{t("tutorial.step.9.title")}</b>
              <br /> <br />
              {t("tutorial.step.9.content")}
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: "#ongoing-alerts-button",
          content: (
            <div>
              <b>{t("tutorial.step.10.title")}</b>
              <br /> <br />
              {t("tutorial.step.10.content")}
            </div>
          ),
          disableBeacon: true,
        },
      ]}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          width: "500px",
          zIndex: 10000,
        },
        tooltipContainer: {
          textAlign: "left",
        },
      }}
    />
  );
}
