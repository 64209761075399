import { ongoingAlertsConstants } from "constants/ongoingAlertsConstants";

export default (
  state = {
    alerts: [],
  },
  action = {}
) => {
  switch (action.type) {
    case ongoingAlertsConstants.ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.payload.alerts,
      };
    default:
      return state;
  }
};
