import { useEffect, useState } from "react";

export default function useSinceTime(date, isActive = true) {
  const [time, setTime] = useState();

  useEffect(() => {
    let sinceTimer = null;

    if (isActive) {
      const startLaunchDate = new Date(date);
      const now = new Date();

      setTime(Number.parseInt((now.getTime() - startLaunchDate.getTime()) / 1000) /* - 3600 */);

      sinceTimer = setInterval(() => {
        setTime((actualTime) => actualTime + 1);
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(sinceTimer);
    }
    return () => clearInterval(sinceTimer);
  }, [date, isActive]);

  return time;
}
