import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import SoftBox from "components/SoftBox";

import config from "config";

export default function HeatMap({ center, zoom, dataset }) {
  const { t } = useTranslation();

  /**
   * Récupère les références de la carte et de l'API pour être utilisé par d'autres fonctions
   * @param {*} map - La carte
   * @param {*} api - L'API Google Maps
   */
  function apiHasLoaded(map, api) {
    map.setOptions({
      zoomControl: true,
      mapTypeControl: true,
      streetViewControl: false,
      rotateControl: false,
      scaleControl: false,
      fullscreenControl: false,
      mapTypeControlOptions: {
        style: api.MapTypeControlStyle.DEFAULT,
        position: api.ControlPosition.TOP_LEFT,
        mapTypeIds: [api.MapTypeId.ROADMAP, api.MapTypeId.HYBRID],
      },
    });

    if (dataset) {
      const heatmapData = dataset.map((aed) => new api.LatLng(aed.lat, aed.lon));
      new api.visualization.HeatmapLayer({
        data: heatmapData,
        opacity: 0.7,
        radius: 40,
        map: map,
      });
    }
  }

  return (
    <SoftBox sx={{ width: "100%", height: "55vh" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.GMAP_KEY,
          libraries: ["places", "geometry", "visualization"],
          language: t("language"),
          region: t("region"),
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps: googleMapsApi }) => apiHasLoaded(map, googleMapsApi)}
      />
    </SoftBox>
  );
}
