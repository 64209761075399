import { accountConstants } from "../constants";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case accountConstants.ACCOUNT_THIRD_LIST:
      return action.payload;
    case accountConstants.ACCOUNT_THIRD_SELECTED:
      return {
        thirdAccount: state.thirdAccount,
        selectedAccount: action.payload.selectedAccount,
      };
    default:
      return state;
  }
};
