import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function useGetAlertType(alert) {
  const { t } = useTranslation();
  const alertTypesList = useSelector((store) => store.alertState.options.alertTypeList);
  const [type, setType] = useState({});

  useEffect(() => {
    const id =
      alert.alertTypeFilters && JSON.parse(alert.alertTypeFilters).optionId
        ? JSON.parse(alert.alertTypeFilters).optionId
        : alert.alertType;
    const label = t(
      alertTypesList.find(
        (alertType) => alertType.id.toString().replace("personal-", "") === id?.toString()
      )?.label
    );
    setType({ id, label });
  }, [alert]);

  return type;
}
