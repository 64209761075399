import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import CitizensRespondersGrowthCard from "./CitizensRespondersGrowthCard/CitizensRespondersGrowthCard";
import CitizensRespondersSkillsCard from "./CitizensRespondersSkillsCard/CitizensRespondersSkillsCard";
import HeatmapDialog from "../HeatmapDialog/HeatmapDialog";
import useGetCitizensRespondersHeatmap from "hooks/indicators/useGetCitizensRespondersHeatmap";

export default function MonitoringCitizensResponders({ filter }) {
  const { t } = useTranslation();
  const [openHeatmap, setOpenHeatmap] = useState(false);
  const heatmapData = useGetCitizensRespondersHeatmap({
    area: filter.area,
  });

  return (
    <SoftBox component="section" py={5}>
      <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <SoftTypography variant="h4">
          {t("dashboard.monitoringCitizensResponders.title")}
        </SoftTypography>
        <SoftTypography
          variant="h5"
          color="primary"
          sx={{
            fontWeight: "500",
            textDecoration: "underline",
            " &:hover": { textDecoration: "inherit", cursor: "pointer" },
          }}
          onClick={() => setOpenHeatmap(true)}
        >
          {t("dashboard.monitoringCitizensResponders.open.aedsHeatmap")}
        </SoftTypography>
        <HeatmapDialog
          data={heatmapData}
          open={openHeatmap}
          onClose={() => setOpenHeatmap(false)}
          title={t("dashboard.dialog.citizensRespondersHeatmap.title")}
        />
      </SoftBox>
      <Grid container spacing={3} alignItems="stretch" pt={4}>
        <Grid item xs={9} alignItems="stretch">
          <CitizensRespondersGrowthCard filter={filter} />
        </Grid>
        <Grid item xs={3}>
          <CitizensRespondersSkillsCard filter={filter} />
        </Grid>
      </Grid>
    </SoftBox>
  );
}
