import PropTypes from "prop-types";

// @mui core components
import { AppBar, Divider, Toolbar } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Customs FDBS components
import HelpButton from "./HelpButton/HelpButton";
import NotificationButton from "./NotificationButton/NotificationButton";
import OngoingAlertsButton from "./OngoingAlertsButton/OngoingAlertsButton";
import SideMenuButton from "./SideMenuButton/SideMenuButton";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer } from "fdbs/components/Navbars/DashboardNavbar/styles";

// Image
import logoBS from "fdbs/assets/logos/SA.png";

function DashboardNavbar({ pageTitle }) {
  return (
    <AppBar position="sticky" color="inherit" sx={(theme) => navbar(theme)}>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <SoftBox component="img" src={logoBS} alt="Bon Samaritain" width="200px" />
          {pageTitle && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ opacity: "1" }} />
              <SoftTypography
                variant="h5"
                sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
              >
                {pageTitle}
              </SoftTypography>
            </>
          )}
        </SoftBox>
        <OngoingAlertsButton />
        <SoftBox sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <SoftBox sx={{ minWidth: "135px" }}>
            <HelpButton />
            <NotificationButton />
          </SoftBox>
          <SideMenuButton />
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  pageTitle: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  pageTitle: PropTypes.string,
};

export default DashboardNavbar;
