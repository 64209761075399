import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, SvgIcon } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import useGetAlertType from "hooks/useGetAlertType";

export default function AlertFormOverview({ onWantToUpdate }) {
  const { t } = useTranslation();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const alertType = useGetAlertType(currentAlert);
  return (
    <Grid container spacing={1} py={2} px={5}>
      <Grid item xs={12}>
        <SoftTypography variant="h6">{t("alert.form.title.ongoingAlert")} :</SoftTypography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          "& p": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            lineHeight: "1rem",
          },
        }}
      >
        {currentAlert.address && (
          <SoftTypography variant="body2">{currentAlert.address}</SoftTypography>
        )}
        {alertType.label && <SoftTypography variant="body2">{alertType.label}</SoftTypography>}
        {currentAlert.addressInfo && (
          <SoftTypography variant="body2">{currentAlert.addressInfo}</SoftTypography>
        )}
      </Grid>
      <Grid item xs={12}>
        <SoftButton
          id="update-button"
          variant="text"
          color="primary"
          sx={{
            textDecoration: "underline",
            "& svg": { fontSize: "1.2rem!important" },
            paddingX: "0",
          }}
          onClick={() => onWantToUpdate()}
        >
          <SvgIcon viewBox="0 0 16 16" sx={{ marginRight: "8px" }}>
            <path
              d="M15 5.31692C15.0001 5.16736 14.9706 5.01925 14.9132 4.88116C14.8558 4.74307 14.7716 4.61772 14.6655 4.51237L11.484 1.32978C11.2696 1.11846 10.9807 1 10.6797 1C10.3787 1 10.0898 1.11846 9.87544 1.32978L1.33452 9.87365C1.22375 9.98296 1.13655 10.1138 1.07829 10.2581L1.05694 10.3151C1.01959 10.4325 1.00039 10.555 1 10.6782V13.8608C1 14.1629 1.11998 14.4527 1.33354 14.6663C1.54711 14.88 1.83676 15 2.13879 15H5.32028C5.44416 15.0007 5.56714 14.979 5.68327 14.9359L5.74021 14.9217C5.88449 14.8634 6.01529 14.7762 6.12456 14.6653L12.0819 8.70599L12.3238 9.69566L9.70463 12.3158C9.5977 12.4238 9.53772 12.5696 9.53772 12.7216C9.53772 12.8736 9.5977 13.0195 9.70463 13.1274C9.81346 13.2327 9.95893 13.2916 10.1103 13.2916C10.2617 13.2916 10.4072 13.2327 10.516 13.1274L13.363 10.2795C13.4297 10.2081 13.4782 10.1216 13.5042 10.0273C13.5303 9.93308 13.5331 9.83394 13.5125 9.73838L13.0214 7.77328L14.6655 6.12147C14.7716 6.01612 14.8558 5.89077 14.9132 5.75268C14.9706 5.61459 15.0001 5.46648 15 5.31692ZM2.13879 11.8174L4.18149 13.8608H2.13879V11.8174ZM5.55516 13.6258L2.37367 10.4432L8.40213 4.41269L11.5836 7.59529L5.55516 13.6258ZM12.3879 6.79074L9.20641 3.60815L10.6797 2.13433L13.8612 5.31692L12.3879 6.79074Z"
              fill="#EC6707"
            />
          </SvgIcon>
          {t("alert.form.overview.button")}
        </SoftButton>
      </Grid>
    </Grid>
  );
}
