import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TimerIcon from "@mui/icons-material/Timer";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { fancyTimeFormat } from "utils";
import useSinceTime from "hooks/useSinceTime";
import useGetAlertType from "hooks/useGetAlertType";

export default function OngoingAlertCard({ alert, onSelect }) {
  const { t } = useTranslation();
  const sinceTime = useSinceTime(alert.cdate);
  const alertType = useGetAlertType(alert);
  const loadedAlert = useSelector((store) => store.alertState.alert);
  const actualLoadedAlert = loadedAlert.alertId === alert.id;

  return (
    <SoftBox
      onClick={() => onSelect(alert)}
      p={1.875}
      mx={0.25}
      my={1.5}
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgba(0,0,0, 0.25)",
        transition: "0.2s",
        ...(actualLoadedAlert
          ? { border: "1px solid #384764" }
          : { cursor: "pointer", "&:hover": { boxShadow: "0px 0px 4px rgba(0,0,0, 0.7)" } }),
      }}
    >
      <SoftBox mb={1.5}>
        <SoftTypography variant="body2">
          <SoftTypography component="span" sx={{ fontWeight: "600" }}>
            {t("ongoingAlerts.list.card.location")} :{" "}
          </SoftTypography>
          {alert.address}
        </SoftTypography>
        {alertType.label ? (
          <SoftTypography variant="body2">
            <SoftTypography component="span" sx={{ fontWeight: "600" }}>
              {t("ongoingAlerts.list.card.typology")} :{" "}
            </SoftTypography>
            {alertType.label}
          </SoftTypography>
        ) : null}
        {alert.addressInfo ? (
          <SoftTypography
            variant="body2"
            sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          >
            <SoftTypography component="span" sx={{ fontWeight: "600" }}>
              {t("ongoingAlerts.list.card.detail")} :{" "}
            </SoftTypography>
            {alert.addressInfo}
          </SoftTypography>
        ) : null}
      </SoftBox>
      <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <SoftBox>
          <SoftTypography variant="body2">
            <TimerIcon sx={{ verticalAlign: "middle" }} /> {t("ongoingAlerts.list.card.since")}{" "}
            {fancyTimeFormat(sinceTime)}
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography
            variant="body2"
            color={actualLoadedAlert ? "inherit" : "primary"}
            sx={{ textDecoration: "underline" }}
          >
            {actualLoadedAlert
              ? t("ongoingAlerts.list.card.current")
              : t("ongoingAlerts.list.card.access")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
