/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "actions/userAction";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
// import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

import DropdownCountries from "fdbs/components/DropdownCountries";

// Soft UI Dashboard PRO React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Custom styles for DashboardNavbar
// import { navbarIconButton } from "fdbs/components/Navbars/DashboardNavbar/styles";

// Custom styles for DashboardNavbar
// import styles from "examples/Navbars/DefaultNavbar/styles/defaultNavbar";

import logoBS from "fdbs/assets/logos/SA.png";

function DefaultNavbar({ transparent, light }) {
  // console.log(routes);
  // const classes = styles({ transparent, light });
  // const [mobileNavbar, setMobileNavbar] = useState(false);
  // const [mobileView, setMobileView] = useState(false);

  // const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  // const closeMobileNavbar = () => setMobileNavbar(false);
  const currentUser = useSelector((state) => state.userState);

  // useEffect(() => {
  //   // A function that sets the display state for the DefaultNavbarMobile.
  //   function displayMobileNavbar() {
  //     if (window.innerWidth < breakpoints.values.lg) {
  //       setMobileView(true);
  //       setMobileNavbar(false);
  //     } else {
  //       setMobileView(false);
  //       setMobileNavbar(false);
  //     }
  //   }

  //   /**
  //    The event listener that's calling the displayMobileNavbar function when
  //    resizing the window.
  //   */
  //   window.addEventListener("resize", displayMobileNavbar);

  //   // Call the displayMobileNavbar function to set the state with the initial value.
  //   displayMobileNavbar();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", displayMobileNavbar);
  // }, []);

  const reduxDispatch = useDispatch();
  const handleLogout = () => {
    reduxDispatch(logout());
  };

  return (
    <Container>
      <SoftBox
        py={1}
        px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
        my={2}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
          <SoftBox component="img" src={logoBS} alt="Bon Samaritain" width="50%" />
        </SoftBox>
        <SoftBox>
          <DropdownCountries />
          {currentUser.isLoggedIn && (
            <IconButton onClick={handleLogout} sx={{ marginLeft: "20px", fontSize: "16px" }}>
              <Icon className={light ? "text-white" : "text-dark"}>power_settings_new</Icon>
            </IconButton>
          )}
        </SoftBox>
        {/* <SoftBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon className="" fontSize="default">
            {mobileNavbar ? "close" : "menu"}
          </Icon>
        </SoftBox> */}
      </SoftBox>
      {/* {mobileView && (
        <DefaultNavbarMobile routes={routes} open={mobileNavbar} close={closeMobileNavbar}>
          Hello
        </DefaultNavbarMobile>
      )} */}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
};
// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbar;
