import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import SoftSelect from "components/SoftSelect";

import iconType1 from "fdbs/assets/icons/type_1_arretCardiaque.png";
import iconType2 from "fdbs/assets/icons/type_2_AVC.png";
import iconType3 from "fdbs/assets/icons/type_3_AVP.png";
import iconType4 from "fdbs/assets/icons/type_4_hemorragie.png";
import iconType5 from "fdbs/assets/icons/type_5_autreUrgenceMedicale.png";
import iconType6 from "fdbs/assets/icons/type_6_departDeFeu.png";
import iconType7 from "fdbs/assets/icons/type_7_personneInconsciente.png";

const { Control, Option, SingleValue } = components;
const CustomControl = ({ children, ...props }) => {
  return (
    <div style={props.isDisabled ? {} : { backgroundColor: "white", borderRadius: "8px" }}>
      <Control {...props}>{children}</Control>
    </div>
  );
};
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={props.data.icon}
      style={{ width: 15, verticalAlign: "middle", marginRight: "0.5rem" }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <img
      src={props.data.icon}
      style={{ width: 15, verticalAlign: "middle", marginRight: "0.5rem" }}
      alt={props.data.label}
    />
    {props.data.label}
  </SingleValue>
);

export default forwardRef(function AlertTypeSelect(
  { defaultValue, error, isDisabled, onChange, options, setOptions },
  ref
) {
  const { t } = useTranslation();
  const currentAlertTypeList = useSelector((store) => store.alertState.options.alertTypeList);

  function getIcon(idType) {
    switch (idType) {
      case 1:
        return iconType1;
      case 2:
        return iconType2;
      case 3:
        return iconType3;
      case 4:
        return iconType4;
      case 5:
        return iconType5;
      case 6:
        return iconType6;
      case 7:
        return iconType7;
      default:
        return iconType1;
    }
  }
  /** Création de la liste des types d'alertes au format attendu par le composant */
  useEffect(() => {
    if (currentAlertTypeList) {
      setOptions(
        currentAlertTypeList.map((alertType) => ({
          value: alertType.id,
          icon: getIcon(alertType.idType),
          label: t(alertType.label),
          isDisabled: alertType.isDisabled,
        }))
      );
    }
  }, [currentAlertTypeList]);

  return (
    <SoftSelect
      id="form-alertType"
      ref={ref}
      options={options}
      error={error}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      onChange={(event) => onChange(event?.value)}
      placeholder={t("alert.form.type.placeholder")}
      components={{ Control: CustomControl, Option: IconOption, SingleValue: IconSingleValue }}
    />
  );
});
