import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

import { useSelector } from "react-redux";
import AlertTypeSelect from "./AlertTypeSelect/AlertTypeSelect";
import HelperText from "./HelperText/HelperText";

export default function AlertForm({ onCancel, onChange, onSubmit }) {
  const { t } = useTranslation();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);
  const [alertTypeError, setAlertTypeError] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  const alertTypeRef = useRef(null);
  const selectTypeRef = useRef(null);

  /** Remplir le formulaire au chargement d'une alerte */
  useEffect(() => {
    if (currentAlert.alertId > 0 && alertTypeOptions) {
      document.getElementById("form-address").value = currentAlert.address;
      document.getElementById("form-detail").value = currentAlert.addressInfo;

      const alertTypeOptionId = getAlertTypeId();

      if (alertTypeOptionId) {
        alertTypeRef.current = alertTypeOptions.find(
          (option) => option.value.toString().replace("personal-", "") === alertTypeOptionId
        );
        selectTypeRef.current.setValue(alertTypeRef.current);
      }
    } else {
      document.getElementById("form-address").value = "";
      document.getElementById("form-detail").value = "";
      selectTypeRef.current.clearValue();
    }
  }, [currentAlert.alertId, currentAlert.address, currentAlert.addressInfo, alertTypeOptions]);

  /**
   * Gestionnaire lorsque le type d'alerte est modifié
   * @param {string} value - Identifiant du type d'alerte sélectionné
   */
  function alertTypeHandler(value = "") {
    const alertTypeOptionId = getAlertTypeId();
    const comparableValue = value.toString().includes("personal-")
      ? value.toString().replace("personal-", "")
      : value.toString();
    if (
      currentAlert.close !== 1 &&
      currentAlert.status !== 2 &&
      comparableValue !== alertTypeOptionId
    ) {
      alertTypeRef.current = value;
      setAlertTypeError(false);
      onChange({ alertType: alertTypeRef.current });
    }
  }

  /**
   * Gestionnaire lorsque le détail de l'alerte est modifié
   * @param {string} value - Nouvelle valeur détaillant l'alerte
   */
  function alertDetailHandler(value) {
    if (currentAlert.close !== 1) {
      onChange({ detail: value });
    }
  }

  function getAlertTypeId() {
    const alert = currentAlert;
    return alert.alertTypeFilters && JSON.parse(alert.alertTypeFilters).optionId
      ? JSON.parse(alert.alertTypeFilters).optionId.toString()
      : alert.alertType
      ? alert.alertType.toString()
      : "";
  }

  /**
   * Actions à la soumission du formulaire
   */
  async function handleSubmit() {
    setDisableForm(true);
    const launched = await onSubmit();
    if (launched) setDisableForm(false);
  }

  return (
    <Grid container spacing={1.5} py={2} px={5}>
      <Grid item xs={12}>
        <SoftTypography variant="h6">
          {currentAlert.status === 2
            ? t("alert.form.title.ongoingAlert")
            : t("alert.form.title.newAlert")}{" "}
          :
        </SoftTypography>
      </Grid>
      <Grid className="tuto-form-address" item xs={12}>
        <SoftInput
          id="form-address"
          placeholder={t("alert.form.address.placeholder")}
          size="large"
          disabled={disableForm || currentAlert.close === 1}
          error={currentAlert.wrongAddress}
          sx={{ paddingRight: "2rem" }}
          endAdornment={
            <InputAdornment position="end" sx={{ position: "absolute", right: "0.75rem" }}>
              {currentAlert.address && currentAlert.status === 1 ? (
                <IconButton
                  onClick={() => (document.getElementById("form-address").value = "")}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <SearchIcon sx={{ fontSize: "1.2rem!important" }} />
              )}
            </InputAdornment>
          }
        />
        {currentAlert.wrongAddress && (
          <HelperText text={t("alert.form.error.wrongAddress")} color="error" />
        )}
        {!currentAlert.wrongAddress && currentAlert.address && currentAlert.status === 1 && (
          <HelperText text={t("alert.form.info.movePin")} color="info" />
        )}
      </Grid>
      <Grid item xs={12} className="tuto-form-type">
        <AlertTypeSelect
          ref={selectTypeRef}
          options={alertTypeOptions}
          error={alertTypeError}
          defaultValue={alertTypeRef.current}
          isDisabled={disableForm || currentAlert.close === 1 || currentAlert.status === 2}
          onChange={(event) => alertTypeHandler(event)}
          setOptions={(newOptions) => setAlertTypeOptions(newOptions)}
        />
      </Grid>
      <Grid item xs={12} className="tuto-form-detail">
        <SoftInput
          id="form-detail"
          placeholder={t("alert.form.detail.placeholder")}
          size="large"
          multiline
          rows={4}
          disabled={disableForm || currentAlert.close === 1}
          onBlur={(event) => alertDetailHandler(event.target.value)}
        />
        {currentAlert.address &&
          currentAlert.alertType &&
          !currentAlert.addressInfo &&
          currentAlert.status === 2 && (
            <HelperText text={t("alert.form.info.giveDetails")} color="info" />
          )}
      </Grid>

      <Grid item xs={12}>
        {currentAlert.status === 2 ? (
          <SoftBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SoftButton
              variant="text"
              color="dark"
              sx={{ textDecoration: "underline" }}
              onClick={() => onCancel()}
            >
              {t("alert.form.button.cancel")}
            </SoftButton>
            <SoftButton
              variant="outlined"
              color="dark"
              sx={(theme) => ({
                "& svg": { fontSize: "1.5rem!important" },
                verticalAlign: "baseline",
                backgroundColor: theme.palette.white.main,
              })}
              onClick={() => handleSubmit()}
            >
              <AutorenewIcon sx={{ marginRight: "8px" }} /> {t("alert.form.button.updateAlert")}
            </SoftButton>
          </SoftBox>
        ) : (
          <SoftButton
            className="tuto-form-submit"
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            disabled={disableForm || currentAlert.close === 1 || currentAlert.alertId === 0}
            onClick={() => handleSubmit()}
          >
            {t("alert.form.button.launchAlert")}
          </SoftButton>
        )}
      </Grid>
    </Grid>
  );
}
