import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import CitizenResponderCard from "./CitizenResponderCard/CitizenResponderCard";
import { CircularProgress, SvgIcon } from "@mui/material";

function CitizenResponders({ launchVideo }) {
  const { t } = useTranslation();
  const currentAlert = useSelector((store) => store.alertState.alert);
  const currentCitizenResponders = useSelector((store) => store.alertState.citizenResponder);

  const onVideoClicked = (responder) => {
    launchVideo(responder);
  };

  return (
    <SoftBox id="bs-zone" sx={{ overflow: "auto", height: "100%" }}>
      <SoftBox
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: theme.palette.white.main,
          borderTop: "2px solid #384764",
        })}
      >
        <SoftBox sx={{ textAlign: "center" }}>
          <SvgIcon>
            <path
              d="M13.0498 0.500001L7.0198 10.9963L0.989776 0.5L13.0498 0.500001Z"
              fill="#384764"
              stroke="#384764"
            />
          </SvgIcon>
        </SoftBox>
        <SoftBox sx={{ paddingX: "2.5rem" }}>
          {currentAlert.status === 1 && (
            <SoftBox id="potential-bs">
              <SoftTypography variant="subtitle1" component="span" fontWeight="medium" color="info">
                {currentAlert.nbPotentialBS ? currentAlert.nbPotentialBS : 0}
              </SoftTypography>{" "}
              <SoftTypography variant="h5" component="span">
                {t("alert.citizens.potential", { s: currentAlert.nbPotentialBS > 1 ? "s" : "" })}
              </SoftTypography>
            </SoftBox>
          )}
          {currentAlert.status === 2 && (
            <>
              <SoftTypography variant="subtitle1" component="span" fontWeight="medium" color="info">
                {currentCitizenResponders?.length ? currentCitizenResponders.length : 0}
              </SoftTypography>{" "}
              <SoftTypography variant="h5" component="span">
                {t("alert.citizens.engaged", {
                  s: currentCitizenResponders?.length > 1 ? "s" : "",
                })}
              </SoftTypography>
              {!currentCitizenResponders?.length && (
                <SoftBox my={2} mr={2} sx={{ textAlign: "center" }}>
                  <CircularProgress color="info" />
                </SoftBox>
              )}
            </>
          )}
        </SoftBox>
        <SoftBox
          id="bs-list"
          sx={{
            overflow: "auto",
            paddingLeft: "2.5rem",
            paddingRight: "1rem",
            marginRight: "1rem",
            marginBottom: "0.25rem",
          }}
        >
          {currentCitizenResponders?.length > 0 &&
            currentCitizenResponders.map((citizenResponder) => (
              <CitizenResponderCard
                key={citizenResponder.citizenResponderId}
                citizenResponder={citizenResponder}
                alertId={currentAlert.alertId}
                displayVideo={(responder) => onVideoClicked(responder)}
              />
            ))}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

CitizenResponders.defaultProps = { launchVideo: PropTypes.func };
CitizenResponders.propTypes = { launchVideo: PropTypes.func };

export default CitizenResponders;
